import React from 'react';

const Unauthorised = () => {
    return (
        <>
        <h1 className="heading">401 - Unauthorised</h1>
        <p className="left-align-text">You do not have permission to view this page.</p>
        <p className="left-align-text">If you need access, please contact HR.</p>
        </>
    )
}

export default Unauthorised;