import { useMsal } from "@azure/msal-react";
import React, { useContext, useState } from "react";
import saveDocs from "../api/saveDocuments";
import submitDocs from "../api/submitDocuments";
import "../css/task.css";
import DocumentsContext from "../globalContext/documentsContext";
import { ifUserIs } from "../utils/getState";
import History from "../utils/history";

const AddComment = ({ required, color, contrastColor, documentID }) => {
  const [comment, setComment] = useState("");
  const [sendToAll, setSendToAll] = useState(false);
  const { documents, setDocuments } = useContext(DocumentsContext);

  let document = documents.find((document) => document.id === documentID);

  let { accounts, instance } = useMsal();

  let image;

  if (ifUserIs(accounts[0].username, document.colleague))
    image = document.colleague.imgUrl;
  else if (ifUserIs(accounts[0].username, document.manager))
    image = document.manager.imgUrl;
  else if (ifUserIs(accounts[0].username, document.grandmanager))
    image = document.grandmanager.imgUrl;
  else image = "";

  let type = "";
  if (document.documentType === "HR1000") type = "probationary review";
  else if (document.documentType === "HR1001B") type = "comments and scores";
  else if (document.documentType.includes("HR1001")) type = "objectives";
  else if (document.documentType === "HR1002") type = "year-end review";

  let userId = localStorage.getItem("userId");
  if (userId === null) userId = accounts[0].username;

  const updateDocument = () => {
    let me = {
      emailAddress: accounts[0].username,
      id: userId,
      imgUrl: image,
      managerName: accounts[0].name,
      name: accounts[0].name,
    };

    let now = new Date().toLocaleString("en-GB");

    let managerEyesOnly =
      ifUserIs(accounts[0].username, document.grandmanager) && !sendToAll
        ? 1
        : 0;

    let commentObj = {
      body: comment,
      commenter: me,
      dateCreated: now,
      flags: managerEyesOnly,
    };

    setDocuments(
      documents.map((d) => {
        if (d.id === documentID) {
          if (d.comments === null) d.comments = [];
          d.comments.push(commentObj);
        }
        return d;
      })
    );

    setComment("");

    if (required) {
      let completed = ifUserIs(accounts[0].username, document.grandmanager)
        ? true
        : false;
      submitDocs(
        accounts[0],
        instance,
        completed,
        documents.find((x) => x.id === documentID)
      );
      History.goBack();
    } else {
      saveDocs(
        accounts[0],
        instance,
        documents.find((document) => document.id === documentID)
      );
    }
  };

  return (
    <article className="add-comment">
      {required && ifUserIs(accounts[0].username, document.colleague) && (
        <>
          <p className="left-align-text">
            If th
            {type !== "objectives" && type !== "comments and scores"
              ? "is"
              : "ese"}{" "}
            {type} do
            {type !== "objectives" && type !== "comments and scores" && "es"}{" "}
            not reflect what was discussed, please do not click on "Accept",
            speak to your Manager immediately to get it resolved.
          </p>
          <p className="left-align-text">
            If you are in agreement with th
            {type !== "objectives" && type !== "comments and scores"
              ? "is"
              : "ese"}{" "}
            {type}, please make a comment in the box below and click “Accept”.
          </p>
          <p className="left-align-text">
            Once you click accept, your {type} will be finalised and sent to HR.
            Your Manager and their Manager will receive a copy.
          </p>
        </>
      )}
      {required && ifUserIs(accounts[0].username, document.grandmanager) && (
        <>
          <p className="left-align-text">
            If you have any issues with th
            {type !== "objectives" ? "is review" : "ese objectives"}, or you
            would like to give some feedback, please leave a comment below.
          </p>
        </>
      )}
      <textarea
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        placeholder="Type your comment here..."
      ></textarea>
      {ifUserIs(accounts[0].username, document.grandmanager) && (
        <>
          <label
            for="check"
            style={
              comment.length > 0
                ? {
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "30px",
                    background: "var(" + color + ")",
                    color: "var(" + contrastColor + ")",
                    marginBottom: "10px",
                    borderRadius: "5px",
                  }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "30px",
                    background: "var(--disabled-button-color)",
                    color: "black",
                    marginBottom: "10px",
                    borderRadius: "5px",
                  }
            }
          >
            Tick this box if you want {document.colleague.name} to see your
            comment, otherwise it will only be visible to yourself,{" "}
            {document.manager.name} and HR.{" "}
            <input
              type="checkbox"
              id="check"
              name="check"
              disabled={comment.length <= 0}
              checked={sendToAll}
              onChange={(e) => setSendToAll(e.target.checked)}
            ></input>
          </label>
        </>
      )}
      <button
        onClick={() => comment.length > 0 && updateDocument()}
        style={
          comment.length > 0
            ? {
                backgroundColor: "var(" + color + ")",
                color: "var(" + contrastColor + ")",
              }
            : {
                backgroundColor: "var(--disabled-button-color)",
                cursor: "default",
              }
        }
      >
        {required
          ? ifUserIs(accounts[0].username, document.colleague)
            ? "Accept"
            : "Submit"
          : "Add comment"}
      </button>
    </article>
  );
};

export default AddComment;
