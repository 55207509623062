import React from 'react';
import formatDate from '../utils/formatDate';

const ObjectiveCard = ({objective}) => {
    return (
        <div className="card">
            <header>
                <h4>{objective.objectiveTitle}</h4>
                <div className="due-date">
                    <h2>{formatDate(objective.completionDate)}</h2>
                    <p>Due Date</p>
                </div>
            </header>
            <div className="obj-body">
                <section>
                    <h4 className="left-align-text">Objective Description</h4>
                    <p>{objective.objectiveDescription}</p>
                </section>
                {objective.objectiveType && <section>
                    <h4 className="left-align-text">Objective Type</h4>
                    <p>{objective.objectiveType}</p>
                </section>}
                {objective.colleagueComments && <section>
                    <h4 className="left-align-text">Colleague Comments</h4>
                    <p>{objective.colleagueComments}</p>
                </section>}
                {objective.colleagueScore && <section>
                    <h4 className="left-align-text">Colleague Score</h4>
                    <p>{objective.colleagueScore}</p>
                </section>}
                {objective.managerComments && <section>
                    <h4 className="left-align-text">Manager Comments</h4>
                    <p>{objective.managerComments}</p>
                </section>}
                {objective.agreedScore && <section>
                    <h4 className="left-align-text">Agreed Score</h4>
                    <p>{objective.agreedScore}</p>
                </section>}
            </div>
        </div>
    )
}

export default ObjectiveCard;


