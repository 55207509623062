import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { setDocumentDueDate } from '../api/adminDocumentUpdate';
import createDocument from '../api/createDocument';
import fetchSecondmentList, { secondmentListAdd, secondmentListRemove } from '../api/secondment';
import LoadingSpinner from '../components/loading-spinner';
import '../css/dashboard.css';

const Secondment = () => {
    const { accounts, instance } = useMsal();

    const [secondmentList, setSecondmentList] = useState([]);
    const [newEmail, setNewEmail] = useState("");
    const [loading, setLoading] = useState(true);

    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            let people = await fetchSecondmentList(accounts[0], instance);
            if(people && people.length > 0) {
                var emails = people.map(person => {
                    return person['emailAddress'];
                });
                setSecondmentList(emails);
            }
            setLoading(false);
        }

        function fetchData() {
            if(isAuthenticated) {
                setTimeout(() => fetch(), 500)
            }
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeEmail = async (email) => {
        let success = await secondmentListRemove(accounts[0], instance, email);
        let newSecondmentList = JSON.parse(JSON.stringify(secondmentList));

        if(success) {
            const index = secondmentList.indexOf(email);

            if (index > -1) {
                newSecondmentList.splice(index, 1);
                setSecondmentList(newSecondmentList);
            }
        }
        else {
            alert("Failed. This email has already been removed from the secondment list. Please refresh the page.")
        }
        
        setNewEmail("");
    }

    const addEmail = async () => {
        if(secondmentList.includes(newEmail)) {
            alert("Failed. This email is already on the secondment list. Please refresh the page if you can't see it.")
        }
        else {
            let success = await secondmentListAdd(accounts[0], instance, newEmail);
            if(success) {
                setSecondmentList([...secondmentList, newEmail]);
            }
            else {
                alert("Failed. No user was found with this email address. Please make sure you are using their email from Bamboo, and that it is spelt correctly.")
            }
        }

        setNewEmail("")
    }

    const createObjectivesForSecondmentGroup = async () => {
        if(window.confirm("WARNING: This will generate objective setting documents due in 2 weeks for everyone on the list. Are you sure you want to continue?"))
        {
            let peopleWeGeneratedFor = [];
            let now = new Date().toISOString();
            let twoWeeksFromNow = new Date(DateTime.fromISO(now).plus({weeks: 2}).toISO());
            for(let email of secondmentList) {
                let document = await createDocument(accounts[0], instance, "HR1001", email);
                if(document) {
                    peopleWeGeneratedFor.push(email)
                    await setDocumentDueDate(accounts[0], instance, document.id, twoWeeksFromNow);
                }
            }

            alert("Generated objective setting documents for " + peopleWeGeneratedFor.toString());
        }
    }

    const createObjectivesForIndividual = async (email) => {
        if(window.confirm(`WARNING: This will generate an objective setting document due in 2 weeks for ${email}. Are you sure you want to continue?`))
        {
            let now = new Date().toISOString();
            let twoWeeksFromNow = new Date(DateTime.fromISO(now).plus({weeks: 2}).toISO());
  
            let document = await createDocument(accounts[0], instance, "HR1001", email);
            if(document) {
                await setDocumentDueDate(accounts[0], instance, document.id, twoWeeksFromNow);
            }

            alert("Generated an objective setting document for " + email);
        }
    }

    return (
        <>
            {loading ?
                <LoadingSpinner />
                :
                <>
                    <h2>Create objectives for the graduates on secondment</h2>
                    <p>Click this button to generate objective setting documents for the graduates in the list below.</p>
                    <p>Make sure the list below is correct and that each graduate has their secondment manager and grandmanager set properly in Bamboo before clicking the button.</p>
                    
                    <button onClick={() => createObjectivesForSecondmentGroup()}>Create objective setting documents for the graduates listed below</button>
                    
                    <h2>Secondment List</h2>
                    <h3>Type an email address in here to add the assosiated user to the secondment list.</h3>
                    <div className="text-and-button">
                        <input onChange={(e) => setNewEmail(e.target.value)} value={newEmail}></input>
                        <button onClick={() => addEmail()}>Submit</button>
                    </div>
                    {secondmentList.map(email => {
                        return (
                            <div className="text-and-button">
                                <h3>{email}</h3>
                                <div>
                                    <button onClick={() => createObjectivesForIndividual(email)}>Generate Setting Document</button>
                                    <button onClick={() => removeEmail(email)}>Remove</button>
                                </div>
                            </div>
                        )
                    })}
                </>
            }
        </>
    )
}

export default Secondment;