import makeApiRequest, { BadRequestError, NotFoundError } from './makeApiRequest';

export default async function updateManager(account, instance, id, name) {
    if (window.confirm(`Are you sure you want to update all documents where ${name} is the colleague? This is irreversible!`)) {
        try {
            await makeApiRequest(account, instance, 'put', 'UpdateColleagueManagerGrandmanagerOnDocuments', {userId: id});
            window.location.reload();
        }
        catch (error) {
            if (error instanceof NotFoundError) {
                alert(`404 Error (Not Found). Failed to update ${name}'s documents.`)
            }
            if (error instanceof BadRequestError) {
                alert(`400 Error (Bad Request). Failed to update ${name}'s documents.`)
            }
        }
    }
}