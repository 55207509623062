import makeApiRequest, { ForbiddenError, NotFoundError } from './makeApiRequest';

export default async function fetchSecondmentList(account, instance) {
    try {
        return await makeApiRequest(account, instance, 'get', 'SecondmentListGet');
    } catch (error) {
        if (error instanceof ForbiddenError) {
            return false;
        }
    }
}

export async function secondmentListRemove(account, instance, email) {
    try {
        return await makeApiRequest(account, instance, 'put', 'SecondmentListRemove', {userEmail: email});
    }
    catch (error) {
        if (error instanceof ForbiddenError) {
            return false;
        }
        else if (error instanceof NotFoundError) {
            return false;
        }
    }
}

export async function secondmentListAdd(account, instance, email) {
    try {
        return await makeApiRequest(account, instance, 'put', 'SecondmentListAdd', {userEmail: email});
    }
    catch (error) {
        if (error instanceof ForbiddenError) {
            return false;
        }
        else if (error instanceof NotFoundError) {
            return false;
        }
    }
}