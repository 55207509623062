import makeApiRequest from './makeApiRequest';

export default async function requestDivisionEmail(account, instance, division) {
    try {
        //return await makeApiRequest(account, instance, 'post', '--https://prod-02.uksouth.logic.azure.com:443/workflows/117c41e5a71a4d8fa5deb1ab22724c49/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=0MQfdBk9maheABiJ3nTbaqvUDIy9LLb5yixYPhKCiBw', { name: account.name, division, email: account.username, noToken: true });
        return await makeApiRequest(account, instance, 'post', 'https://prod-31.uksouth.logic.azure.com:443/workflows/3b466972dd80473f869d4467239a8053/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=TEsSAu0A_YqOyqotohBLHiIL5MjFvyjBEqDb_ofFT00', { name: account.name, division, email: account.username, noToken: true });
    }
    catch {
        return false;
    }
}