const formatDate = (datetime, notime) => {
    let dateWithoutTime = datetime.split(" ")[0];
    let dateArray = dateWithoutTime.split("/");
    for(let i = 0; i < dateArray.length; i++) {
        if (dateArray[i].length < 2) {
            dateArray[i] = "0" + dateArray[i];
        }
    }
    let ukDate = dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2].substr(2,4);
    return ukDate;
}

export const formatTime = (datetime) => {
    let time = datetime.split(" ")[1];
        time = time.substr(0, time.length-3);

        let ampm = datetime.split(" ")[2].toLowerCase();
        
        return time + ampm;
}

export default formatDate;