import { useMsal } from '@azure/msal-react';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import saveDocs from '../../api/saveDocuments';
import submitDocs from '../../api/submitDocuments';
import ProbationarySlide from '../../components/probationary-slide';
import ViewerTable from '../../components/viewer-table';
import DocumentsContext from '../../globalContext/documentsContext';
import { getState, ifUserIs } from '../../utils/getState';
import History from '../../utils/history';
import TaskWrapper from './task-wrapper';

const ProReviewEditor = () => {
    const { documentID } = useParams();

    // Get all documents
    const { documents, setDocuments } = useContext(DocumentsContext);
    
    const [slide, setSlide] = useState(0);

    // Get the specific document to display
    const [document, setDocument] = useState(documents.find(x => x.id === documentID));
    let body;
    let color;

    const { accounts, instance } = useMsal();
    let loggedInUsersEmail = accounts[0].username;

    if (document) {
        body = document?.body;
        color = getState(document, loggedInUsersEmail).color;
    }

    const updateDocument = (e) => {
        let newBody = body;
        if (e.target.value.includes("Select a score...")) {
            newBody[slide][e.target.name] = ""; 
        }
        else {
            newBody[slide][e.target.name] = e.target.value; 
        }
        
        let newDocument = document;
        newDocument.body = newBody;
        setDocument(newDocument); 

        let newDocuments = JSON.parse(JSON.stringify(documents));
        newDocuments[newDocuments.findIndex(x => x.id === documentID)] = newDocument;
        setDocuments(newDocuments);
    }

    const canSwapSlide = () => {
        if(!body) return false;
        if (document.documentState <= 0) {
            if (body[slide].colleagueScore !== "" && body[slide].colleagueComments !== "") {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            if(body[slide].managerScore !== "" && body[slide].managerComments !== "") {
                return true;
            }
            else if(body[slide].categoryName === "Third Party Feedback" && body[slide].managerComments !== "") {
                return true;
            }
            else {
                return false;
            }
        }
    }

    const nextSlideIsThirdPartyFeedback = () => {
        if(!body[slide+1]) {
            return false
        }
        return ifUserIs(accounts[0].username, document.colleague) && body[slide+1].categoryName === "Third Party Feedback"
    }

    const prevSlideIsThirdPartyFeedback = () => {
        if(!body[slide-1]) 
            return false
        return ifUserIs(accounts[0].username, document.colleague) && body[slide-1].categoryName === "Third Party Feedback"
    }

    const nextSlide = () => {
        if(canSwapSlide()) 
        {
            saveDocs(accounts[0], instance, document);
            if (nextSlideIsThirdPartyFeedback()) {
                setSlide(slide+2)
            }
            else {
                setSlide(slide+1)
            }
        }
    }

    const previousSlide = () => {
        saveDocs(accounts[0], instance, document);
        if (prevSlideIsThirdPartyFeedback()) {
            setSlide(slide-2)
        }
        else {
            setSlide(slide-1)
        }
    }

    const submit = () => {
        submitDocs(accounts[0], instance, false, document);
        History.goBack();
        if(ifUserIs(accounts[0].username, document.colleague)) 
        {
            alert("Your comments are now with your manager. They will arrange a meeting to discuss your progress with you.")
        }
    }

    return (
        <>
        <TaskWrapper document={document}>
            {slide < document.body.length ? 
                <>
                    {ifUserIs(accounts[0].username, document.colleague) &&
                        <article className="objective-text">
                            <p>Consider how you have performed {document.documentType === "HR1002" ? "this year" : "since you joined the business"}  against the following categories. </p>
                            <p>You should include some examples where possible.</p>
                        </article>
                    }
                    {ifUserIs(accounts[0].username, document.manager) &&
                        <article className="objective-text">
                            <p>Once you have held {document.colleague.name}'s {document.documentType === "HR1002" ? "year-end" : "probationary"} review meeting, please input your comments and the agreed score</p>
                        </article>
                    }
                    <ProbationarySlide state={document.documentState} data={body[slide]} criterias={document.criterias} setValue={(e) => updateDocument(e)}/>
                </>
            :
            <>
                {ifUserIs(accounts[0].username, document.manager) && 
                    <article className="objective-text">
                        <p>Please review the comments and score, once finalised the {document.documentType === "HR1000" ? "probationary" : "year-end"} review can’t be amended and will be sent back to {document.colleague.name} for their final comment. </p>
                    </article>
                }
                <div className="document prob-viewer">
                    <ViewerTable document={document}/>              
                </div>
            </>
            }

            <div className="slide-buttons">
                {slide-1 >= 0 &&
                    <button onClick={() => previousSlide()} style={{backgroundColor: 'var(--primary-button-color)'}}>Previous Section</button>
                }
                
                {slide+1 <= document.body.length ? 
                    <button onClick={() => nextSlide()} style={canSwapSlide() ? {backgroundColor: 'var(' + color + ')'} : {backgroundColor: 'var(--disabled-button-color)', cursor: "default"}}>{slide+1 < document.body.length && !nextSlideIsThirdPartyFeedback() ? <> Save & continue to the next section: {document.body[slide+1].categoryName} </> : <> Review comments before submission </>}</button>
                :
                    <button onClick={() => submit()} style={{backgroundColor: 'var(' + color + ')'}}>{document.documentState === 0 ? "Send my comments to " + document.manager.name : "Finalise " + (document.documentType === "HR1000" ? "probationary" : "year-end") + " review"}</button>
                }
            </div>
        </TaskWrapper>
        </>
    )
}

export default ProReviewEditor;