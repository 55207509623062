import React from 'react';
import "../css/loadingSpinner.css";

const LoadingSpinner = () => {
    return (
        <div class="loading-spinner">
            <div className="parent"><div className="child"></div></div>
        </div>
    )
}

export default LoadingSpinner;