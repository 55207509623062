
export const ifUserIs = (loggedInUsersEmail, subject) => {
    // return true if the ids match, use email as a fallback
    let loggedInUsersId = localStorage.getItem('userId');
    let usingEmail = false;

    if(loggedInUsersId === null) {
        loggedInUsersId = loggedInUsersEmail;
        usingEmail = true;
    }

    if(loggedInUsersId === null || subject === null || subject.id === null || subject.emailAddress === null) {
        return false;
    } 
    else {
        if(usingEmail) {
            return (loggedInUsersEmail.toLowerCase() === subject.emailAddress.toLowerCase());  
        }
        else {
            return (loggedInUsersId === subject.id);
        }
    }
}

export const ifUserIsNot = (loggedInUsersEmail, subject) => {
    // return true if the emails do NOT match
    return !ifUserIs(loggedInUsersEmail, subject)
}

const workOutIteration = (iteration) => {
    switch(iteration) {
        case null:
            return "";
        case "0":
            return "2 month "
        case "1":
            return "4 month "
        case "2":
            return "6 month "
        case "3":
            return "extended "
        default:
            return ""
    }
}

export const slideState = (document, loggedInUsersEmail, type) => {
    const baseState = {
        color: "--" + type + "-color",
        contrastColor: "--" + type + "-contrast-color",
        type: type,
        lastState: 3
    }

    switch(document.documentState) {
        case(-1):
            return {
                ...baseState,
                link: "/" + type + "-review/view/" + document.id + "/false/true",
                description: document.colleague.name + "'s " + workOutIteration(document.iteration) + type + " review",
                requiresAcceptance: false,
                awaitingResponse: false,
                responder: null
            } 
        case(0):
            return {
                ...baseState,
                link: "/" + type + "-review/edit/" + document.id,
                description: ifUserIsNot(loggedInUsersEmail, document.colleague) ? document.colleague.name + "'s " + workOutIteration(document.iteration) + type + " review" : type === "year-end" ? "Please add comments and a rating to your values review as part of your year-end review. " : "Start writing your " + workOutIteration(document.iteration) + type + " review",
                requiresAcceptance: false,
                awaitingResponse: ifUserIsNot(loggedInUsersEmail, document.colleague),
                responder: document.colleague
            }
        case(1):
            return {
                ...baseState,
                link: "/" + type + "-review/edit/" + document.id,
                description: ifUserIsNot(loggedInUsersEmail, document.manager) ? document.colleague.name + "'s " + workOutIteration(document.iteration) + type + " review" : "Add final comments & score to " + document.colleague.name + "'s " + workOutIteration(document.iteration) + type + " review",
                requiresAcceptance: false,
                awaitingResponse: ifUserIsNot(loggedInUsersEmail, document.manager),
                responder: document.manager
            } 
        case(2):
            return {
                ...baseState,
                link: "/" + type + "-review/view/" + document.id + "/false/false",
                description: ifUserIsNot(loggedInUsersEmail, document.colleague) ? document.colleague.name + "'s " + workOutIteration(document.iteration) + type + " review" : "Please write a comment on your " + workOutIteration(document.iteration) + type + " review",
                requiresAcceptance: true,
                awaitingResponse: ifUserIsNot(loggedInUsersEmail, document.colleague),
                responder: document.colleague
            } 
        case(3):
            return {
                ...baseState,
                link: "/" + type + "-review/view/" + document.id + "/false/false",
                description: ifUserIsNot(loggedInUsersEmail, document.grandmanager) ? document.colleague.name + "'s " + workOutIteration(document.iteration) + type + " review" : "Write a comment on " + document.colleague.name + "'s " + workOutIteration(document.iteration) + type + " review",
                requiresAcceptance: true,
                awaitingResponse: ifUserIsNot(loggedInUsersEmail, document.grandmanager),
                responder: document.grandmanager
            } 
        default:
            return {
                color: "--error-color",
                contrastColor: "white",
                type: type,
                link: "",
                description: "State Error: " + document.colleague.name + "'s " + type + " review document is broken, please contact IT for assistance. Document ID: " + document.id,
                requiresAcceptance: false,
                awaitingResponse: true,
                responder: null
            } 
    }
}

export const objectivesState = (document, loggedInUsersEmail, type) => {
    const baseState = {
        color: "--" + type + "-color",
        contrastColor: "--" + type + "-contrast-color",
        type: type,
        lastState: 2
    }

    switch(document.documentState) {
        case(-1):
            return {
                ...baseState,
                link: "/" + type + "-review/view/" + document.id + "/false/true",
                description: document.colleague.name + "'s " + type + " review",
                requiresAcceptance: false,
                awaitingResponse: false,
                responder: null
            } 
        case(0):
            return {
                ...baseState,
                link: "/" + type + "-review/edit/" + document.id,
                description: ifUserIsNot(loggedInUsersEmail, document.manager) ? document.colleague.name + "'s objective setting" : "Please set some objectives for " + document.colleague.name,
                requiresAcceptance: false,
                awaitingResponse: ifUserIsNot(loggedInUsersEmail, document.manager),
                responder: document.manager
            } 
        case(1):
            return {
                ...baseState,
                link: "/" + type + "-review/view/" + document.id + "/false/false",
                description: ifUserIsNot(loggedInUsersEmail, document.colleague) ? document.colleague.name + "'s objective setting" : "Please review your " + type,
                requiresAcceptance: true,
                awaitingResponse: ifUserIsNot(loggedInUsersEmail, document.colleague),
                responder: document.colleague
            } 
        case(2):
            return {
                ...baseState,
                link: "/" + type + "-review/view/" + document.id + "/false/false",
                description: ifUserIsNot(loggedInUsersEmail, document.grandmanager) ? document.colleague.name + "'s objective setting" : "Please review " + document.colleague.name + "'s " + type,
                requiresAcceptance: true,
                awaitingResponse: ifUserIsNot(loggedInUsersEmail, document.grandmanager),
                responder: document.grandmanager
            } 
        default:
            return {
                color: "--error-color",
                type: type,
                link: "",
                description: "State Error: " + document.colleague.name + "'s " + type + " review document is broken, please contact IT for assistance. Document ID: " + document.id,
                requiresAcceptance: false,
                awaitingResponse: true,
                responder: null
            } 
    }
}

export const objMidEndState = (document, loggedInUsersEmail, type, period) => {
    const baseState = {
        color: "--" + type + "-color",
        contrastColor: "--" + type + "-contrast-color",
        type: type,
        lastState: 3
    }
    switch(document.documentState) {
        case(-1):
            return {
                ...baseState,
                link: "/" + type + "-review/view/" + document.id + "/false/true",
                description: document.colleague.name + "'s " + period + " " + type + " review",
                requiresAcceptance: false,
                awaitingResponse: false,
                responder: null
            } 
        case(0):
            return {
                ...baseState,
                link: "/" + type + "-review/edit/" + document.id,
                description: ifUserIsNot(loggedInUsersEmail, document.colleague) ? document.colleague.name + "'s " + period + " " + type + " review" : "Please comment on your progress for each of your objectives as part of your " + period + " review",
                requiresAcceptance: false,
                awaitingResponse: ifUserIsNot(loggedInUsersEmail, document.colleague),
                responder: document.colleague
            } 
        case(1):
            return {
                ...baseState,
                link: "/" + type + "-review/edit/" + document.id,
                description: ifUserIsNot(loggedInUsersEmail, document.manager) ? document.colleague.name + "'s " + period + " " + type + " review" : "Please leave a comment on each of " + document.colleague.name + "'s " + period + " " + type,
                requiresAcceptance: false,
                awaitingResponse: ifUserIsNot(loggedInUsersEmail, document.manager),
                responder: document.manager
            }
        case(2):
            return {
                ...baseState,
                link: "/" + type + "-review/view/" + document.id + "/false/false",
                description: ifUserIsNot(loggedInUsersEmail, document.colleague) ? document.colleague.name + "'s " + period + " " + type + " review" : "Please write a comment on your " + period + " " + type + " review",
                requiresAcceptance: true,
                awaitingResponse: ifUserIsNot(loggedInUsersEmail, document.colleague),
                responder: document.colleague
            } 
        case(3):
            return {
                ...baseState,
                link: "/" + type + "-review/view/" + document.id + "/false/false",
                description: ifUserIsNot(loggedInUsersEmail, document.grandmanager) ? document.colleague.name + "'s " + period + " " + type + " review" : "Please write a comment on " + document.colleague.name + "'s " + period + " " + type + " review",
                requiresAcceptance: true,
                awaitingResponse: ifUserIsNot(loggedInUsersEmail, document.grandmanager),
                responder: document.grandmanager
            } 
        default:
            return {
                color: "--error-color",
                type: type,
                link: "",
                description: "State Error: " + document.colleague.name + "'s " + type + " review document is broken, please contact IT for assistance. Document ID: " + document.id,
                requiresAcceptance: false,
                awaitingResponse: true,
                responder: null
            } 
    }
}

export const getState = (document, loggedInUsersEmail) => {
    switch(document.documentType) {
        case("HR1000"):
            return slideState(document, loggedInUsersEmail, "probationary")
        case("HR1001"):
            return objectivesState(document, loggedInUsersEmail, "objectives")
        case("HR1001A"):
            return objMidEndState(document, loggedInUsersEmail, "objectives", "mid-year")
        case("HR1001B"):
            return objMidEndState(document, loggedInUsersEmail, "objectives", "year-end")
        case("HR1002"):
            return slideState(document, loggedInUsersEmail, "year-end")
        default:
            return {
                color: "--error-color",
                type: "unknown",
                link: "",
                description: "Type Error: " + document.colleague.name + " has an unknown type of document, please contact IT for assistance. Document ID: " + document.id,
                requiresAcceptance: false,
                awaitingResponse: true,
                responder: null
            }
    }
}