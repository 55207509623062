import makeApiRequest, { ForbiddenError } from './makeApiRequest';

export default async function fetchIsAdmin(account, instance, all) {
    try {
        return await makeApiRequest(account, instance, 'get', 'IsAdmin');
    } catch (error) {
        if (error instanceof ForbiddenError) {
            return false;
        }
    }
}