import { useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "../css/task.css";
import formatDate, { formatTime } from "../utils/formatDate";
import { getState } from "../utils/getState";
import Avatar from "../components/avatar";

const AdminDocumentCard = ({ document, deleteDoc, resetDoc, changeDate }) => {
  const { accounts } = useMsal();

  let loggedInUsersEmail = accounts[0].username;
  let state = getState(document, loggedInUsersEmail);
  let late = false;

  let legacy = document.documentState === -1;

  let dateToConvert = legacy ? document.dateCompleted : document.dateDue;

  const [dueDate, setDueDate] = useState(dateToConvert);

  let date = formatDate(dateToConvert);

  if (!legacy) {
    let dueDateObject = new Date(dateToConvert);
    let today = new Date();

    if (dueDateObject < today) {
      // Overdue
      late = true;
    }
  }

  const lastUpdated = () => {
    let dateString =
      document.submitEvents[document.submitEvents.length - 1].updateTime;

    let date = formatDate(dateString);
    let time = formatTime(dateString);

    return date + " at " + time;
  };

  let stateDict = {
    0: "Not Started",
    1: "In Progress (Step 1)",
    2: "In Progress (Step 2)",
    3: "In Progress (Step 3)",
    4: "In Progress (Step 4)",
    5: "In Progress (Step 5)",
    6: "In Progress (Step 6)",
    7: "In Progress (Step 7)",
    8: "In Progress (Step 8)",
    9: "In Progress (Step 9)",
    10: "In Progress (Step 10)",
    "-1": "Completed",
  };

  return (
    <div
      className={"task" + (late ? " late" : "")}
      style={{
        borderColor: "var(" + (late ? "--late-color" : state.color) + ")",
      }}
    >
      <div className={"task-left"}>
        {
          <Avatar
            url={document.colleague.imgUrl}
            email={document.colleague.emailAddress}
          />
        }
        <article>
          <p className="description">
            {legacy && <p className="completed-tag">Completed</p>}
            {state.description}
          </p>
          {
            <p className="last-updated">
              {"This document is " +
                stateDict[document.documentState] +
                ". " +
                (!legacy
                  ? "Waiting for a response from " + state.responder.name + ". "
                  : "") +
                (document.submitEvents !== null
                  ? "Last updated on " + lastUpdated() + "."
                  : "")}
            </p>
          }
        </article>
      </div>
      <div className="task-right">
        <button
          style={{ display: "flex" }}
          title="Delete this document"
          onClick={() =>
            window.confirm(
              "Are you sure you want to delete this document? THIS CANNOT BE UNDONE!"
            ) && deleteDoc(document.id)
          }
        >
          Delete<i class="fas fa-times" style={{ marginLeft: "10px" }}></i>
        </button>
        <button
          style={{ display: "flex" }}
          onClick={() =>
            window.confirm(
              "Are you sure you want to reset this document back to the beginning? THIS CANNOT BE UNDONE!"
            ) && resetDoc(document.id)
          }
          title="Reset this document"
        >
          Reset to beginning
          <i class="fas fa-redo-alt" style={{ marginLeft: "10px" }}></i>
        </button>
        <div className={"due-date " + (late ? "late" : "")}>
          {legacy ? (
            <h2>{date}</h2>
          ) : (
            <DatePicker
              selected={new Date(dueDate)}
              onChange={(date) => {
                if (
                  window.confirm(
                    "Are you sure you want to change this document's due date? THIS CANNOT BE UNDONE!"
                  )
                ) {
                  changeDate(document.id, date);
                  setDueDate(date);
                }
              }}
              selectsStart
              dateFormat="dd/MM/yyyy"
              strictParsing
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          )}
          <p>{legacy ? "Completion Date" : "Due Date"}</p>
        </div>
      </div>
    </div>
  );
};

export default AdminDocumentCard;
