import { DateTime } from 'luxon';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../css/objectives-editor.css';
import '../css/probationary-editor.css';

const EditObjective = ({isColleague, isManager, objective, type, saveObjective, deleteObjective, closeEditor}) => {
    // Form fields
    const [title, setTitle] = useState(objective !== -1 ? objective.objectiveTitle : "");
    const [description, setDescription] = useState(objective !== -1 ? objective.objectiveDescription : "");
    const [completionDate, setCompletionDate] = useState(objective !== -1 ? objective.completionDate : "");
    const [colleagueComments, setColleagueComments] = useState(objective !== -1 ? objective.colleagueComments : "");
    const [managerComments, setManagerComments] = useState(objective !== -1 ? objective.managerComments : "");
    const [colleagueScore, setColleagueScore] = useState(objective !== 1 ? objective.colleagueScore : "");
    const [agreedScore, setAgreedScore] = useState(objective !== -1 ? objective.agreedScore : "");
    const [objectiveType, setObjectiveType] = useState(objective !== -1 ? objective.objectiveType : "Performance Objective");
    
    // Create the edited objective object from the current field state
    const editedObjective = {
        objectiveTitle: title,
        objectiveDescription: description,
        completionDate: completionDate,
        agreedScore: agreedScore,
        colleagueScore: colleagueScore,
        managerComments: managerComments,
        colleagueComments: colleagueComments,
        objectiveType: objectiveType
    }

    let completionDateObject;

    let tenYearsFromNow = new Date(DateTime.fromISO(new Date().toISOString()).plus({years: 10}).toISO());
    
    if(completionDate) {
        // Make a date object from the string-based completion date
        let dateArray = completionDate.split("/");
        completionDateObject = new Date(dateArray[2], dateArray[0]-1, dateArray[1]);
    }

    const formatDateObjectIntoUK = (date) => {
        // Puts the date into UK format
        let dateString = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
        setCompletionDate(dateString);
    }

    const canSubmit = () => {
        if (title.length > 0 && description.length > 0 && completionDate.length > 0 && objectiveType.length > 0) {
            if (isColleague) {
                if (colleagueComments.length > 0) {
                    if(type.includes("B")) {
                        if (colleagueScore.length > 0) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return false;
                }
            }
            else {
                if(colleagueComments.length > 0) {
                    if(managerComments.length > 0) {
                        if(colleagueScore.length > 0) {
                            if(agreedScore.length > 0) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                        else {
                            return true;
                        }
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return true;
                }
            }
        }
        else {
            return false;
        }
    }

    return (
        <>
            <div className="objectives-editor">
                <title>
                    {isManager  ? 
                        <>
                            {type.includes('B') ? 
                                <h4>{title}</h4> 
                            :
                            <>
                                <h4>Objective Title</h4>
                                <input onChange={(e) => setTitle(e.target.value)} value={title} />
                            </>
                            }
                        </>
                    : isColleague && <h4>{title}</h4>}
                </title>
                <article>
                    {isManager ? 
                        <>
                            <h4>Objective Description</h4>
                            {type.includes('B') ? <p>{description}</p> : <textarea onChange={(e) => setDescription(e.target.value)} value={description} />}

                            <h4>Objective Type</h4>
                            {type.includes('B') ?
                                <p>{objectiveType}</p>
                                :
                                <select value={objectiveType} onChange={(e) => setObjectiveType(e.target.value)}>
                                    <option name="Performance Objective">Performance Objective</option>
                                    <option name="Development Objective">Development Objective</option>
                                </select>
                            }

                            {colleagueComments && 
                            <>
                                <h4>Colleague Comments</h4>
                                <p>{colleagueComments}</p>
                            </>}
                            
                            {colleagueComments && 
                                <>
                                    <h4>Manager Comments</h4>
                                    <textarea onChange={(e) => setManagerComments(e.target.value)} value={managerComments} />
                                </>
                            }
                            <h4>Completion Date</h4>
                            {type.includes('B') ?
                                <p>{completionDate}</p>
                            :
                                <DatePicker
                                selected={completionDateObject ? completionDateObject : new Date()}
                                onChange={date => formatDateObjectIntoUK(date)}
                                minDate={new Date()}
                                maxDate={tenYearsFromNow}
                                dateFormat="dd/MM/yyyy"
                                strictParsing
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                />
                            }
                            {colleagueScore && 
                                <>
                                    <h4>Colleague score</h4>
                                    <input className="colleagueScoreBox" disabled="true" value={colleagueScore} />
                                    <h4>Agreed Score</h4>
                                    <p>Rate their performance on this objective</p>
                                    <select name="agreedScore" value={agreedScore.length > 0 ? agreedScore : "Select a score..."} onChange={(e) => setAgreedScore(e.target.value)}>
                                        <option>Select a score...</option>
                                        <option>Exceptional</option>
                                        <option>Good</option>
                                        <option>Acceptable</option>
                                        <option>Below</option>
                                    </select>
                                </>
                            }
                        </>
                    : isColleague &&
                        <>
                            <div className="read-only">
                                <div>
                                    <h4>Description</h4>
                                    <p>{description}</p>
                                </div>
                                <div>
                                    <h4>Due Date</h4>
                                    <p>{completionDate}</p>
                                </div>
                            </div>
                            {managerComments && 
                                <>
                                    <h4>Manager Comments</h4>
                                    <p>{managerComments}</p>
                                </>
                            }
                            <h4>My Comments</h4>
                            <textarea onChange={(e) => setColleagueComments(e.target.value)} value={colleagueComments} />
                            {type.includes("B") && 
                                <>
                                    <h4>My Score</h4>
                                    <p>Rate your performance on this objective</p>
                                    <select name="colleagueScore" value={colleagueScore.length > 0 ? colleagueScore : "Select a score..."} onChange={(e) => setColleagueScore(e.target.value)}>
                                        <option>Select a score...</option>
                                        <option>Exceptional</option>
                                        <option>Good</option>
                                        <option>Acceptable</option>
                                        <option>Below</option>
                                    </select>
                                </>
                            }
                        </>
                    }
                </article>
                <div className="buttons">
                    <button className="delete" onClick={() => window.confirm("Are you sure you want to undo your changes to this objective?") && closeEditor()}>Undo changes</button>
                    {/*(objective !== -1 && isManager && !type.includes('B')) && <button className="delete" onClick={() => window.confirm("Are you sure you want to delete this objective? It will be removed from the objective list.") && deleteObjective(objective)}>Delete</button>*/}
                    <button className="submit" onClick={() => canSubmit() && saveObjective(editedObjective)} style={canSubmit() ? {backgroundColor: "var(--objectives-color)", color: "var(--objectives-contrast-color)"} : {backgroundColor: 'var(--disabled-button-color)', cursor: "default"}}>Save Objectives</button>
                </div>
            </div>
        </>
    )
}

export default EditObjective;