import makeApiRequest from './makeApiRequest';

export default async function deleteDocument(account, instance, id) {
    try {
        return await makeApiRequest(account, instance, 'post', 'DeleteDocument?documentId=' + id);
    }
    catch {
        return false;
    }
}

export async function resetDocument(account, instance, id) {
    try {
        return await makeApiRequest(account, instance, 'put', 'ResetDocument', {documentId: id});
    }
    catch {
        return false;
    }
}

export async function setDocumentDueDate(account, instance, id, date) {
    try {
        return await makeApiRequest(account, instance, 'put', 'SetDocumentDueDate', {documentId: id, date: date});
    }
    catch {
        return false;
    }
}