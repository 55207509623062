import makeApiRequest, { ForbiddenError } from './makeApiRequest';

export default async function fetchUsers(account, instance, getAll = false, excludeBlacklist = false) {
    try {
        return await makeApiRequest(account, instance, 'get', 'GetUsers', { getAll, excludeBlacklist });
    } catch (error) {
        if (error instanceof ForbiddenError) {
            return [];
        }
    }
}