import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';
import deleteDocument, { resetDocument, setDocumentDueDate } from '../api/adminDocumentUpdate';
import createDocument from '../api/createDocument';
import { fetchAllDocs } from '../api/getDocumentList';
import updateManager from '../api/updateManager';
import AdminDocumentCard from '../components/admin-document-card';
import LoadingSpinner from '../components/loading-spinner';
import '../css/dashboard.css';

const AdminEditUser = () => {
    const { accounts, instance } = useMsal();
    let {id, name} = useParams();
    if(!id) {
        id = accounts[0].username;
        name = id.split(".")[0].substr(0,1).toUpperCase() + id.split(".")[0].substr(1).toLowerCase() + " " + id.split(".")[1].split('@')[0].substr(0,1).toUpperCase() + id.split(".")[1].split('@')[0].substr(1).toLowerCase();
    }

    const [openedDocument, setOpenedDocument] = useState(null);
    const [metadataDocs, setMetadataDocs] = useState(null);
    const [loading, setLoading] = useState(true);

    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            let docs = await fetchAllDocs(accounts[0], instance, id);
            setMetadataDocs(docs);
            setLoading(false);
        }

        function fetchData() {
            if(isAuthenticated) {
                setTimeout(() => fetch(), 500)
            }
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteFromMetadataDocs = async (documentId) => {
        let success = await deleteDocument(accounts[0], instance, documentId);
        let newMetadataDocs = JSON.parse(JSON.stringify(metadataDocs));

        if(success) {
            const index = metadataDocs.findIndex(doc => doc.id === documentId);

            if(index > -1) {
                newMetadataDocs.splice(index, 1);
                setMetadataDocs(newMetadataDocs);
            }
        }
        else {
            alert("Failed. This document has already been deleted. Please refresh the page.")
        }
    }

    const resetMetadataDoc = async (documentId) => {
        let success = await resetDocument(accounts[0], instance, documentId);
        let newMetadataDocs = JSON.parse(JSON.stringify(metadataDocs));

        if(success) {
            const index = metadataDocs.findIndex(doc => doc.id === documentId);

            if(index > -1) {
                newMetadataDocs[index].documentState = 0;
                setMetadataDocs(newMetadataDocs);
            }
        }
        else {
            alert("Failed. This document couldn't be reset. Please refresh the page and try again.")
        }
        setMetadataDocs(newMetadataDocs);
    }

    const changeDateForMetadataDoc = async (documentId, date) => {
        let success = await setDocumentDueDate(accounts[0], instance, documentId, date);
        let newMetadataDocs = JSON.parse(JSON.stringify(metadataDocs));

        if(success) {
            const index = metadataDocs.findIndex(doc => doc.id === documentId);

            if(index > -1) {
                // Set the date to be about right so it appears in the UI and affects the overdue
                newMetadataDocs[index].dateDue = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
                setMetadataDocs(newMetadataDocs);
            }
        }
        else {
            alert("Failed. This document couldn't be reset. Please refresh the page and try again.")
        }
        setMetadataDocs(newMetadataDocs);
    }

    const confirmAndCreateDocument = async (type, typestring) => {
        if (window.confirm(`Are you sure you want to create ${typestring} for ${name}?`))
        {
            await createDocument(accounts[0], instance, type, id);
        }
        window.location.reload()
    }
        

    return (
        <>
            {loading ?
                <LoadingSpinner />
                :
                <>
                    {openedDocument === null ?   
                        <>  
                            <h1 className="heading">{name}'s Data</h1>
                            {metadataDocs && metadataDocs.length > 0 ? metadataDocs
                                .map((document, index) => {
                                return (
                                    <ErrorBoundary key={index} fallbackRender={({ error }) => (
                                        <p>{error.message}</p>
                                    )}>
                                        <AdminDocumentCard document={document} documentID={document.id} deleteDoc={(id) => deleteFromMetadataDocs(id)} resetDoc={(id) => resetMetadataDoc(id)} changeDate={(id, date) => changeDateForMetadataDoc(id, date)} />
                                    </ErrorBoundary>
                                )
                            }) : <i>{name} has no actionable tasks at the moment.</i>} 
                            <button onClick={async () => updateManager(accounts[0], instance, id, name)}>Update colleague, manager and grandmanager for all open and completed documents where {name} is the colleague.</button>
                            <select
                                onChange={async (e) => confirmAndCreateDocument(...e.target.value.split(";"))}
                                className="dropdown"
                            >
                                <option value="" disabled selected hidden>Click here to choose a document to create for {name}</option>
                                <option value="HR1000;a 2 month probationary review">2 month probationary review</option>
                                <option value="HR1000A;a 4 month probationary review">4 month probationary review</option>
                                <option value="HR1000B;a 6 month probationary review">6 month probationary review</option>
                                <option value="HR1001;an objective setting">Objective setting</option>
                                <option value="HR1001A;an objective mid-year review">Objective mid-year review</option>
                                <option value="HR1001B;an objective end-of-year review">Objective end-of-year review</option>
                                <option value="HR1002;a year end review">Year end review</option>
                            </select> 
                        </>
                    : 
                        <div className="obj-doc">
                            <h1>{openedDocument.id}</h1>
                            <button onClick={() => setOpenedDocument(null)}>Close</button>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default AdminEditUser;