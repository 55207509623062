import { useMsal } from "@azure/msal-react";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import submitDocs from "../api/submitDocuments";
import "../css/task.css";
import DocumentsContext from "../globalContext/documentsContext";
import formatDate, { formatTime } from "../utils/formatDate";
import { getState, ifUserIs } from "../utils/getState";
import Avatar from "./avatar";

const Task = ({ document, legacy, readOnly }) => {
  const { accounts, instance } = useMsal();
  let { documents, setDocuments } = useContext(DocumentsContext);
  let loggedInUsersEmail = accounts[0].username;
  let state = getState(document, loggedInUsersEmail);
  let late = false;

  let dateToConvert = legacy ? document.dateCompleted : document.dateDue;

  let date = formatDate(dateToConvert);

  if (!legacy && !state.awaitingResponse) {
    let dueDate = new Date(dateToConvert);
    let today = new Date();

    if (dueDate < today) {
      // Overdue
      late = true;
    }
  }

  const lastUpdated = () => {
    let dateString =
      document.submitEvents[document.submitEvents.length - 1].updateTime;

    let date = formatDate(dateString);
    let time = formatTime(dateString);

    return date + " at " + time;
  };

  const quickCompleteDocument = (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "Are you sure you want to complete this document without leaving a comment? If you do want to leave a comment, click 'Cancel' and then click the task to read over the document."
      )
    ) {
      submitDocs(accounts[0], instance, true, document);
      let newDocs = JSON.parse(JSON.stringify(documents));
      let index = newDocs.findIndex((x) => x.id === document.id);
      newDocs.splice(index, 1);
      setDocuments(newDocs);
    }
  };

  return (
    <NavLink
      to={
        (readOnly ? "" : !state.awaitingResponse && !legacy)
          ? state.link
          : "/" +
            state.type +
            "-review/view/" +
            document.id +
            "/true" +
            (legacy ? "/true" : "/false")
      }
      className={"task" + (legacy ? " legacy" : "") + (late ? " late" : "")}
      style={{
        borderColor: "var(" + (late ? "--late-color" : state.color) + ")",
      }}
    >
      <div className={"task-left" + (legacy ? " legacy" : "")}>
        {!legacy && (
          <Avatar
            url={document.colleague.imgUrl}
            email={document.colleague.emailAddress}
          />
        )}
        <article>
          <p className="description">{state.description}</p>
          {!legacy && (
            <p className="last-updated">
              {(state.awaitingResponse
                ? "Waiting for a response from " + state.responder.name + ". "
                : "") +
                (document.submitEvents !== null
                  ? "Last updated on " + lastUpdated() + "."
                  : "")}
            </p>
          )}
        </article>
      </div>
      <div className="task-right">
        {!readOnly &&
          !legacy &&
          !state.awaitingResponse &&
          document.documentState !== state.lastState && (
            <NavLink
              className="button-style"
              title="View this document"
              to={
                "/" + state.type + "-review/view/" + document.id + "/true/false"
              }
            >
              <i class="far fa-eye"></i>
            </NavLink>
          )}
        {!readOnly &&
          !legacy &&
          !state.awaitingResponse &&
          document.documentState === state.lastState &&
          ifUserIs(accounts[0].username, document.grandmanager) && (
            <button
              onClick={(e) => quickCompleteDocument(e)}
              title="Complete this document"
            >
              <i class="fas fa-check"></i>
            </button>
          )}
        <div className={"due-date " + (late ? "late" : "")}>
          <h2>{date}</h2>
          <p>{legacy ? "Completion Date" : "Due Date"}</p>
        </div>
      </div>
    </NavLink>
  );
};

export default Task;
