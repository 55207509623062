import { useMsal } from '@azure/msal-react';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import fetchDocument from '../../api/getDocument';
import LoadingSpinner from '../../components/loading-spinner';
import ObjectiveCard from '../../components/objective-card';
import '../../css/objectives-viewer.css';
import DocumentsContext from '../../globalContext/documentsContext';
import DocumentViewWrapper from './document-view-wrapper';

const ObjReviewViewer = () => {
    const { documentID, readOnly, completed } = useParams();
    const { documents } = useContext(DocumentsContext);
    const { accounts, instance } = useMsal();

    const [document, setDocument] = useState(null);

    const [fetching, setFetching] = useState(false);
    
    const getDoc = async () => {
        if(completed === "false") {
            let doc = documents.find(x => x.id === documentID)
            if(doc) {
                setDocument(doc);
            }
            
        }
        else {
            // Fetch the document
            setFetching(true);
            let doc = await fetchDocument(accounts, instance, documentID)
            if(doc !== null) setDocument(doc);
            
        }
    }
    
    if(document === null && !fetching) {
        getDoc();
    }

    return (
        <>
            {document ?
                <DocumentViewWrapper document={document} readOnly={readOnly} completed={completed === "true"}>
                    <h1 className="tr-title">{document.colleague.name}'s Objectives</h1>
                    {document.body !== "" ? 
                        <div className="obj-doc">
                            {document.body.map((objective) => {
                                return <ObjectiveCard objective={objective} />
                            })}
                            {document.body.length < 1 && <p>No objectives have been set yet.</p>}
                        </div>
                    :  <p>This document has not been written yet.</p>}
                </DocumentViewWrapper>
            : <LoadingSpinner />}
        </>
    )
}

export default ObjReviewViewer;