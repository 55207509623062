import makeApiRequest from './makeApiRequest';

export default async function fetchDocs(account, instance) {
    return await makeApiRequest(account, instance, 'get', 'GetIncompleteDocuments', {userId: account.username});
}

export async function fetchCompletedDocs(account, instance, userId) {
    return await makeApiRequest(account, instance, 'get', 'GetCompletedDocuments', {userId: userId});
}

export async function fetchAllDocs(account, instance, userId) {
    return await makeApiRequest(account, instance, 'get', 'GetAllDocuments', {userId: userId});
}