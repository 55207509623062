import { useMsal } from '@azure/msal-react';
import React, { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import '../css/taskList.css';
import DocumentsContext from '../globalContext/documentsContext';
import { getState } from '../utils/getState';
import LoadingSpinner from './loading-spinner';
import Task from './task';

const filterList = (list, awaiting, searchTerms, startDateAsDate, endDateAsDate, allowedAppraisalTypes) => {
    return list
    .filter(document => {
        // Filter awaiting a response
        if((awaiting && document.state.awaitingResponse) || (!awaiting && !document.state.awaitingResponse)) return true;
        else return false;
    })
    .filter(document => {
        // Filter appraisal types
        if(allowedAppraisalTypes.length >= 3 || allowedAppraisalTypes.includes(document.documentType.substring(0,6))) return true;
        else return false;
    })
    .filter(document => {
        // Filter date range
        let myDateArray = document.dateDue.split(" ")[0].split("/");
        let myDate = new Date(myDateArray[2], myDateArray[0] - 1, myDateArray[1]).toISOString();
        if(myDate >= startDateAsDate && myDate <= endDateAsDate) return true; else return false;
    })
    .filter(document => {
        // Search remaining
        for(let index = 0; index < searchTerms.length; index++) {
            if(!document.colleague.name.toLowerCase().includes(searchTerms[index]) && !document.colleague.emailAddress.toLowerCase().includes(searchTerms[index]) && !document.state.description.toLowerCase().includes(searchTerms[index]) && !document.dateDue.toLowerCase().includes(searchTerms[index])) {
                return false;
            } 
        }
        return true;
    })
}

const Tasks = ({documents, filterString, startDate, endDate, allowedAppraisalTypes}) => {
    const { accounts } = useMsal();
    const { loading } = useContext(DocumentsContext);
    let loggedInUsersEmail = accounts[0].username;

    let documentsWithState = documents.map(document => {
        return {
            ...document,
            state: getState(document, loggedInUsersEmail)
        }
    });

    let startDateAsDate = new Date(startDate).toISOString();
    let endDateAsDate = new Date(endDate).toISOString();

    const searchTerms = filterString.toLowerCase().split(" ");

    let actionableTasks = filterList(documentsWithState, false, searchTerms, startDateAsDate, endDateAsDate, allowedAppraisalTypes);
    let awaitingResponse = filterList(documentsWithState, true, searchTerms, startDateAsDate, endDateAsDate, allowedAppraisalTypes);

    return (
        <ol className="tasks">
            <h4>Your Tasks</h4>
            {loading ? <LoadingSpinner />
            :
                <>
                    {actionableTasks.length > 0 ? actionableTasks
                        .map((document, index) => {
                        return (
                            <ErrorBoundary key={index} fallbackRender={({ error }) => (
                                <p>{error.message}</p>
                            )}>
                                <Task document={document} documentID={document.id} />
                            </ErrorBoundary>
                        )
                    }) : <i>You have no actionable tasks at the moment.</i>} 
                </>
            }
            <h4>Awaiting a response</h4>
            {loading ? <LoadingSpinner />
            :
            <>
                {awaitingResponse.length > 0 ? awaitingResponse
                    .map((document, index) => {
                    return (
                        <ErrorBoundary key={index} fallbackRender={({ error }) => (
                            <p>{error.message}</p>
                        )}>
                            <Task document={document} documentID={document.id} />
                        </ErrorBoundary>
                    )
                }) : <i>Nobody is working on your documents at this time.</i>}
            </>
            }
        </ol>
    )
}

export default Tasks;