import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import fetchBlacklist, {
  blacklistAdd,
  blacklistRemove,
} from "../api/blacklist";
import LoadingSpinner from "../components/loading-spinner";
import "../css/dashboard.css";

const Blacklist = () => {
  const { accounts, instance } = useMsal();

  const [blacklist, setBlacklist] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [loading, setLoading] = useState(true);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      let people = await fetchBlacklist(accounts[0], instance);
      if (people && people.length > 0) {
        var emails = people.map((person) => {
          return person["emailAddress"];
        });
        setBlacklist(emails);
      }
      setLoading(false);
    };

    function fetchData() {
      if (isAuthenticated) {
        setTimeout(() => fetch(), 500);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeEmail = async (email) => {
    let success = await blacklistRemove(accounts[0], instance, email);
    let newBlacklist = JSON.parse(JSON.stringify(blacklist));

    if (success) {
      const index = blacklist.indexOf(email);

      if (index > -1) {
        newBlacklist.splice(index, 1);
        setBlacklist(newBlacklist);
      }
    } else {
      alert(
        "Failed. This email has already been removed from the leavers archive. Please refresh the page."
      );
    }

    setNewEmail("");
  };

  const addEmail = async () => {
    if (blacklist.includes(newEmail)) {
      alert(
        "Failed. This email is already on the leavers archive. Please refresh the page if you can't see it."
      );
    } else {
      let success = await blacklistAdd(accounts[0], instance, newEmail);
      if (success) {
        setBlacklist([...blacklist, newEmail]);
      } else {
        alert(
          "Failed. No user was found with this email address. Please make sure you are using their email from Bamboo, and that it is spelt correctly."
        );
      }
    }

    setNewEmail("");
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h2>Leavers Archive</h2>
          <h3>
            Type an email address in here to leavers archive the associated user
          </h3>
          <div className="text-and-button">
            <input
              onChange={(e) => setNewEmail(e.target.value)}
              value={newEmail}
            ></input>
            <button onClick={() => addEmail()}>Submit</button>
          </div>
          {blacklist.map((email) => {
            return (
              <div className="text-and-button">
                <h3>{email}</h3>
                <button onClick={() => removeEmail(email)}>Remove</button>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default Blacklist;
