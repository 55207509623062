import React, { useEffect, useState } from 'react';
import '../css/probationary-editor.css';
import '../css/task.css';

const ProbationarySlide = ({state, data, criterias, setValue}) => {
    const [score, setScore] = useState(state > 0 ? data.managerScore : data.colleagueScore);
    const [comments, setComments] = useState(state > 0 ? data.managerComments : data.colleagueComments);
    const [showingHelp, setShowingHelp ] = useState(false);

    useEffect(() => {
        setScore(state > 0 ? data.managerScore : data.colleagueScore);
        setComments(state > 0 ? data.managerComments : data.colleagueComments);
    }, [state, data])
    
    const updateDocument = (e) => {
        if(e.target.name.includes("Score")) {
            if (e.target.value.includes("Select")) {
                setScore("")
            }
            else {
                setScore(e.target.value)
            }
        }
        else if(e.target.name.includes("Comments")) {
            setComments(e.target.value)
        }
        setValue(e);
    }

    return (
        <article className="probationary-category">
            <div className="category">
                {showingHelp ?
                    <>
                        <h4 className="help">Criteria Descriptions<i onClick={() => setShowingHelp(false)} class="fas fa-times"></i></h4>
                        {criterias.map(criteria => {
                            return <>
                                <h4>{criteria.title}</h4>
                                <p>{criteria.description}</p>
                            </>
                        })}
                    </>
                    :
                    <>
                        <h4>{data.categoryName}</h4>
                        <p style={{marginBottom: "20%"}}>{data.categoryDescription}</p>
                        {data.categoryName !== "Third Party Feedback" && 
                            <>
                                {state > 0 && 
                                    <>
                                        <h4>Colleague score</h4>
                                        <input className="colleagueScoreBox" disabled="true" value={data.colleagueScore} />
                                        <h4 className="help">Please select an agreed score for this category<i onClick={() => setShowingHelp(true)} class="fas fa-question-circle"></i></h4>
                                    </>
                                }
                                {state <= 0 && <h4 className="help">Please rate yourself for this category<i onClick={() => setShowingHelp(true)} class="fas fa-question-circle"></i></h4>}
                                <select name={state > 0 ? "managerScore" : "colleagueScore"} value={score.length > 0 ? score : "Select a score..."} onChange={updateDocument}>
                                    <option>Select a score...</option>
                                    {criterias.map(criteria => <option title={criteria.description}>{criteria.title}</option>)}
                                </select>
                            </>
                        }
                    </>
                }
            </div>
            <div className="input">
                {state > 0 ? 
                <>
                    {data.categoryName !== "Third Party Feedback" && 
                        <>
                            <h4 className="left-align-text">Colleague's comments about {data.categoryName}</h4>
                            <p className="left-align-text">{data.colleagueComments}</p>
                        </>
                    }
                    <h4 className="left-align-text">Manager's comments about {data.categoryName}</h4>
                    <textarea name="managerComments" onChange={updateDocument} value={comments} placeholder="Leave comments here..."></textarea>
                </>
                :
                <>
                    <h4 className="left-align-text">My comments about {data.categoryName}</h4>
                    <textarea name="colleagueComments" onChange={updateDocument} value={comments} placeholder="Leave comments here..."></textarea>
                </>
                }
            </div>
        </article>
    )
}

export default ProbationarySlide;