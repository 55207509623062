import axios from 'axios';
import fetchCurrentUser from './fetchCurrentUser';

export class BadRequestError extends Error { }
export class UnauthorisedError extends Error { }
export class ForbiddenError extends Error { }
export class NotFoundError extends Error { }
export class ServerError extends Error {}
export class UnprocessableEntityError extends Error {}

export default async function makeAPIRequest(account, instance, method, url, params) {
	let accessToken;
	let includeToken = true;

	if(account) {
		let response = await instance.acquireTokenSilent({
			scopes: ["api://dc8336ba-6686-4faa-9c56-24c4603002ea/access_as_user"],
			account: account
		})
		if(response) {
			accessToken = response.accessToken;
		}
	}

	if(params && params.hasOwnProperty('userId')) {
		if(params['userId'].includes("@")) {
			// If we are getting the ID for the logged in user, get from local storage if available
			if(localStorage.getItem('userId') && account.username === params['userId']) {
				params['userId'] = localStorage.getItem('userId');
			}
			else {
				let user = await fetchCurrentUser(account, instance);
				if(account.username === params['userId']) localStorage.setItem('userId', user.id);
				params['userId'] = user.id;
			}
		}
	}

	if(params && params.hasOwnProperty('noToken')) {
		includeToken = false;
		delete params['noToken'];
	}

	try {
		// Make the appropriate HTTP request.
		const res = await axios.request({
			url,
			method,
			headers: {Authorization: includeToken ? 'Bearer ' + accessToken : ''},
			baseURL: process.env.REACT_APP_API,
			params: method === 'get' ? params : undefined,
			data: method !== 'get' ? params : undefined
		});

		return res.data;
	} catch (err) {
		if (err.response) {
			switch (err.response.status) {
				case 400:
					throw new BadRequestError(err.response.data);
				case 401:
					throw new UnauthorisedError(err.response.data);
				case 403:
					throw new ForbiddenError(err.response.data);
				case 404:
					throw new NotFoundError(err.response.data);
				case 422:
					throw new UnprocessableEntityError(err.response.data);
				case 500:
					throw new ServerError(err.response.data);
				default:
					throw new Error("Unhandled error");
			}
		} else if (err.request) {
			// The request was made but no response was received
			// `err.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(err.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', err.message);
		}

		// TODO: Should still fire an API error, it just needs to be a new network-related class.
		throw err;
	}
}