import makeApiRequest, { ForbiddenError, NotFoundError } from './makeApiRequest';

export default async function createDocument(account, instance, documentType, userId) {
    try {
        return await makeApiRequest(account, instance, 'post', "CreateDocument?userId=" + userId + "&documentType=" + documentType);
    } catch (error) {
        if (error instanceof ForbiddenError) {
            return false;
        }
        else if (error instanceof NotFoundError) {
            return false;
        }
    }
}