import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import '../css/comments.css'
import '../css/document-viewer.css'
import DocumentsContext from '../globalContext/documentsContext'
import History from '../utils/history'

const AcceptReject = () => {
    const { documentID } = useParams();
    const { documents, setDocuments } = useContext(DocumentsContext);
    const [ comment, setComment ] = useState("");

    let documentsCopy = documents;

    const updateDocument = (e, approvalState) => {
        let commentObj = {approvalState: approvalState, body: comment, image: "", name: "Anna Bell", rejected: false, time: "now"}
        if(!documentsCopy.find(x => x.id = documentID).comments) documentsCopy.find(x => x.id = documentID).comments = [];
        documentsCopy.find(x => x.id = documentID).comments.push(commentObj);
        setDocuments(documentsCopy);
        History.goBack();
    }

    return (
        <article className="add-comment">
            <textarea onChange={(e) => setComment(e.target.value)} placeholder="Type your comment here..."></textarea>
            <div className="accept-reject-buttons">
                <button onClick={(e) => updateDocument(e, 1)} style={{backgroundColor: "var(--accept-button)"}}>Accept</button>
            </div>
        </article>
    )
}

export default AcceptReject;