import React from 'react';
import Task from '../../components/task';

const TaskWrapper = ({document, children}) => {
    return (
        <>
            <Task document={document}/>
            <main className="task-content">
                {document && children}
            </main>
        </>
    )
}

export default TaskWrapper;