import makeApiRequest, { ForbiddenError } from './makeApiRequest';

export default async function fetchCurrentUser(account, instance) {
    try {
        return await makeApiRequest(account, instance, 'get', 'CurrentUser');
    } catch (error) {
        if (error instanceof ForbiddenError) {
            return [];
        }
    }
}