import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from "react-error-boundary";
import { fetchCompletedDocs } from '../api/getDocumentList';
import '../css/taskList.css';
import { getState } from '../utils/getState';
import LoadingSpinner from './loading-spinner';
import Task from './task';

const CompletedDocuments = ({id}) => {
    const [completedDocs, setCompletedDocs] = useState(null);
    const { accounts, instance } = useMsal();
    const [loading, setLoading] = useState(true);
    let loggedInUsersEmail = accounts[0].username;

    useEffect(() => {
        const getDocs = async () => {
            setLoading(true)
            let docs = await fetchCompletedDocs(accounts[0], instance, id);
            setCompletedDocs(docs);
            setLoading(false)
        }

        if(!completedDocs) {
            getDocs();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    let documentsWithState = completedDocs ? completedDocs.sort((x, y) => new Date(x.dateCompleted) - new Date(y.dateCompleted)).reverse().map(document => {
        return {
            ...document,
            state: getState(document, loggedInUsersEmail)
        }
    }) : [];

    return (
    <ol className="tasks legacy">
        <h4>Documents</h4>
        {loading ? 
            <LoadingSpinner />
        :
        <>
            {documentsWithState.length > 0 ? documentsWithState
                .map((document, index) => {
                return (
                    <ErrorBoundary fallbackRender={({ error }) => (
                        <p>{error.message}</p>
                    )}>
                        <Task document={document} documentID={document.id} key={index} legacy={true}/>
                    </ErrorBoundary>
                )
            }) : <i>You don't have any documents at this time</i>}
        </>
        }
    </ol>
    )
}

export default CompletedDocuments;