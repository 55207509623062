import React from 'react';

const PageNotFound = () => {
    return (
        <>
        <h1 className="heading">404 - Page Not Found</h1>
        <p className="left-align-text">Please check the URL or go back to your dashboard.</p>
        <p className="left-align-text">If the issue persists, please contact IT.</p>
        </>
    )
}

export default PageNotFound;