let root = document.documentElement;

const switchTheme = (theme) => {
    localStorage.setItem('theme', theme)
    switch(theme) {
        case("Dark"):
            root.style.setProperty('--main-bg-color', "#0c0c0c");
            root.style.setProperty('--card-color', "#191919");
            root.style.setProperty('--main-font-color', "white");
            root.style.setProperty('--invert-font-color', "black");
            root.style.setProperty('--input-color', "#0a0a0a");
            root.style.setProperty('--disabled-button-color', '#1f1f1f')
            break;
        case("Light"):
            root.style.setProperty('--main-bg-color', "#ffffff");
            root.style.setProperty('--card-color', "#ececec");
            root.style.setProperty('--main-font-color', "black");
            root.style.setProperty('--invert-font-color', "black");
            root.style.setProperty('--input-color', "#c1c1c1");
            root.style.setProperty('--disabled-button-color', '#ececec')
            break;
        default:
            root.style.setProperty('--main-bg-color', "#0c0c0c");
            root.style.setProperty('--card-color', "#191919");
            root.style.setProperty('--main-font-color', "#white");
    }  
}

export default switchTheme;