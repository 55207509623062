import React from "react";
import "../css/task.css";
import Avatar from "./avatar";

const Comment = ({ data }) => {
  return (
    <article className="comment">
      <header>
        <Avatar url={data.commenter?.imgUrl} email={data.commenter?.email} />
        <h4>
          {data.commenter && data.commenter?.name + " " + data.dateCreated}
        </h4>
      </header>
      {data.approvalState === 1 ? (
        <div className="approved">Approved</div>
      ) : (
        data.approvalState === 2 && <div className="rejected">Rejected</div>
      )}
      <p className="comment-body">{data.body}</p>
    </article>
  );
};

export default Comment;
