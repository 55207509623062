import React from "react";

export function getInitialsFromEmail(email) {
  const default_initials = "x";
  try {
    let parts = email.split(".", 2);
    if (parts.length === 2) {
      return `${parts[0].charAt(0).toLowerCase()}${parts[1]
        .charAt(0)
        .toLowerCase()}`;
    } else return default_initials;
  } catch {
    return default_initials;
  }
}

const Avatar = ({ url, email }) => {
  const initials = getInitialsFromEmail(email);
  return (
    <img
      alt="person"
      src={url}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = `https://i1.wp.com/cdn.auth0.com/avatars/${initials}.png?ssl=1`;
      }}
    />
  );
};

export default Avatar;
