import React from 'react';
import '../css/objective.css';
import formatDate from '../utils/formatDate';


const Objective = ({openEditor, openViewer, objective, status}) => {
    return (
            <card onClick={() => openEditor ? openEditor() : openViewer && openViewer()} className="objective">
                <div className="obj-left">
                    {!status ?
                        <div className="icon">
                            <i className="fas fa-trophy"></i>
                        </div>
                    : status === "commented" ?
                        <div className="commented icon" title="Objective completed">
                            <i className="fas fa-check"></i>
                        </div>
                    : status === "needs-comment" ?
                        <div className="needs-comment icon" title="Please leave some comments on this objective">
                            <i className="fas fa-comment"></i>
                        </div>
                    : status === "needs-score" &&
                        <div className="needs-comment icon" title="Please choose a score for this objective">
                            <i className="fas fa-star"></i>
                        </div>
                    }
                    <p className="description">{objective.objectiveTitle}</p>
                </div>
                <div className="due-date">
                    <h2>{formatDate(objective.completionDate)}</h2>
                    <p>Due Date</p>
                </div>
            </card>
    )
}

export default Objective;