import makeApiRequest from './makeApiRequest';

export default async function saveDocs(account, instance, document) {
    return await makeApiRequest(account, instance, 'put', 'SaveDocument', document);
}

export async function saveAllDocs(account, instance, documents) {
    for(let document in documents) {
        saveDocs(account, instance, document);
    }
}