import makeApiRequest, { ForbiddenError, NotFoundError, ServerError, UnprocessableEntityError } from './makeApiRequest';

export async function createTestDocument(account, instance, documentType, colleagueId, managerId, grandmanagerId) {
    try {
        return await makeApiRequest(account, instance, 'post', "CreateTestDocument?documentType=" + documentType + "&colleagueId=" + colleagueId + "&managerId=" + managerId + "&grandmanagerId=" + grandmanagerId);
    } catch (error) {
        if (error instanceof UnprocessableEntityError) {
            alert(error + " Were the email addresses correct and up to date with the users in Bamboo?");
        }
        else {
            alert(error)
        }
    }
}

export async function deleteTestDocuments(account, instance) {
    try {
        return await makeApiRequest(account, instance, 'post', "DeleteTestDocuments");
    } catch (error) {
        if (error instanceof ForbiddenError) {
            return false;
        }
        else if (error instanceof NotFoundError) {
            return false;
        }
        else if (error instanceof ServerError) {
            return false;
        }
    }
}