import { useMsal } from "@azure/msal-react";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import AddComment from "../../components/add-comment";
import Comment from "../../components/comment";
import Task from "../../components/task";
import "../../css/comments.css";
import "../../css/document-viewer.css";
import { getState, ifUserIs, ifUserIsNot } from "../../utils/getState";

const DocumentViewWrapper = ({ document, readOnly, completed, children }) => {
  const { accounts } = useMsal();
  let loggedInUsersEmail = accounts[0].username;

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const state = getState(document, loggedInUsersEmail);

  let preamble = "";

  if (
    ifUserIs(accounts[0].username, document.manager) &&
    document.documentType === "HR1000"
  )
    preamble =
      "Please review the comments and score, once finalised the probationary review can’t be amended and will be sent back to " +
      document.colleague.name +
      " for their final comment.";
  else if (
    ifUserIs(accounts[0].username, document.colleague) &&
    (document.documentType === "HR1001A")
  )
    preamble =
      "These objectives have been reviewed as a result of your recent discussion with your Manager. Please look over them and make sure they reflect what you agreed during your objective review meeting.";
  else if (
    ifUserIs(accounts[0].username, document.colleague) &&
    (document.documentType === "HR1001B")
  )
    preamble =
      "These objectives have been scored as a result of your recent discussion with your Manager. Please look over them and make sure they reflect what you agreed during your objective review meeting.";
  else if (
    ifUserIs(accounts[0].username, document.colleague) &&
    document.documentType === "HR1001"
  )
    preamble =
      "These objectives have been set as a result of your recent discussion with your Manager. Please review them and make sure they reflect what you agreed during your objective setting meeting.";
  else if (
    ifUserIs(accounts[0].username, document.colleague) &&
    document.documentType === "HR1000"
  )
    preamble =
      "The comments below should reflect your probationary review discussion. Please add a comment to finalise.";
  else if (
    ifUserIs(accounts[0].username, document.manager) &&
    document.documentType === "HR1002"
  )
    preamble =
      "Please review the comments and score, once finalised the year-end review can’t be amended and will be sent back to " +
      document.colleague.name +
      " for their final comment.";

  return (
    <article className="document-viewer">
      <Task document={document} readOnly={true} legacy={completed} />
      {(state.requiresAcceptance && preamble.length > 0) && <p className="objective-text">{preamble}</p>}
      <div ref={printRef}>
        <main className="document" ref={printRef}>
          {document && children}
        </main>
        <header>
          <h4 className="comments-header">Comments</h4>
        </header>
        <section className="comments-section">
          {document.comments !== null ? (
            document.comments.map((comment, index) => {
              if (
                comment.flags === 0 ||
                ifUserIsNot(accounts[0].username, document.colleague)
              ) {
                return <Comment data={comment} key={index} />;
              }
              return null;
            })
          ) : (
            <p>There are no comments on this document</p>
          )}
        </section>
      </div>
      <button onClick={handlePrint}>
        Print a copy of your comments to take with you
      </button>
      {readOnly === "false" && (
        <AddComment
          required={state.requiresAcceptance}
          color={state.color}
          contrastColor={state.contrastColor}
          documentID={document.id}
        />
      )}
    <footer>Document ID: {document.id}</footer>
    </article>
  );
};

export default DocumentViewWrapper;
