import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import fetchObjectives from '../api/getObjectives';
import CompletedDocuments from '../components/completedDocuments';
import LoadingSpinner from '../components/loading-spinner';
import Objective from '../components/objective';
import ObjectiveCard from '../components/objective-card';
import '../css/dashboard.css';

const MyData = () => {
    const { accounts, instance } = useMsal();
    let {id, name} = useParams();
    if(!id) {
        id = accounts[0].username;
        name = id.split(".")[0].substr(0,1).toUpperCase() + id.split(".")[0].substr(1).toLowerCase() + " " + id.split(".")[1].split('@')[0].substr(0,1).toUpperCase() + id.split(".")[1].split('@')[0].substr(1).toLowerCase();
    }

    const [openedObjective, setOpenedObjective] = useState(null);
    const [objectives, setObjectives] = useState(null);
    const [loading, setLoading] = useState(true);

    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            let objs = await fetchObjectives(accounts[0], instance, id);
            setObjectives(objs);
            setLoading(false);
        }

        function fetchData() {
            if(isAuthenticated) {
                setTimeout(() => fetch(), 500)
            }
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {openedObjective === null ?   
                <>  
                    <h1 className="heading">{name}'s Data</h1>
                    <main className="dashboard">
                        <aside className="current-objectives">
                            <h4 className="left-align-text">Current Objectives</h4>
                            {loading ? 
                                <LoadingSpinner />
                            :
                            <>
                                {(objectives && objectives.body && objectives.body.length > 0) ? objectives.body.map((objective) => {
                                    return <Objective openViewer={() => setOpenedObjective(objective)} objective={objective} />
                                }) : <p className="italic">You currently don't have any objectives set, please speak to your manager.</p>}
                            </>
                            }
                        </aside>
                        <CompletedDocuments id={id}/>
                    </main>
                </>
            : 
                <div className="obj-doc">
                    <ObjectiveCard objective={openedObjective} />
                    <button onClick={() => setOpenedObjective(null)}>Close</button>
                </div>
            }
        </>
    )
}

export default MyData;