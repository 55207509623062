import makeApiRequest, { NotFoundError } from './makeApiRequest';

export default async function fetchObjectives(account, instance, userId) {
    try {
        return await makeApiRequest(account, instance, 'get', 'GetUserObjectives', {userId: userId});
    } catch (error) {
        if (error instanceof NotFoundError) {
            return [];
        }
    }
}