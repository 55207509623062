import { useMsal } from '@azure/msal-react';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import fetchDocument from '../../api/getDocument';
import LoadingSpinner from '../../components/loading-spinner';
import ViewerTable from '../../components/viewer-table';
import '../../css/objectives-viewer.css';
import DocumentsContext from '../../globalContext/documentsContext';
import DocumentViewWrapper from './document-view-wrapper';

const ProReviewViewer = () => {
    const { documentID, readOnly, completed } = useParams();
    const { documents } = useContext(DocumentsContext);
    const { accounts, instance } = useMsal();

    const [document, setDocument] = useState(null);

    const [fetching, setFetching] = useState(false);
    
    const getDoc = async () => {
        if(completed === "false") {
            let doc = documents.find(x => x.id === documentID)
            if(doc) {
                setDocument(doc);
            }
        }
        else {
            // Fetch the document
            setFetching(true);
            let doc = await fetchDocument(accounts, instance, documentID)
            if(doc !== null) setDocument(doc);
            
        }
    }
    
    if(document === null && !fetching) {
        getDoc();
    }

    return (
        <>
            {document ?
                <DocumentViewWrapper document={document} readOnly={readOnly}  completed={completed === "true"}>
                    <div className="prob-viewer">
                    {document.body !== "" ? 
                        <ViewerTable document={document} />
                    :
                        <p>This document has not been written yet.</p>
                    }
                    </div>
                </DocumentViewWrapper>
            : <LoadingSpinner />}
        </>
    )
}

export default ProReviewViewer;