import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import fetchIsAdmin from "../api/fetchIsAdmin";
import fetchDocs from "../api/getDocumentList";
import getGDPR from "../api/getGDPRAuth";
import { UnauthorisedError } from "../api/makeApiRequest";
import saveDocs from "../api/saveDocuments";
import setGDPR from "../api/setGDPRAuth";
import { authProvider } from "../authProvider";
import switchTheme from "../css/switchTheme.js";
import DocumentsContext from "../globalContext/documentsContext.js";

const MainPageWrapper = ({ children }) => {
  const [theme, setTheme] = useState();
  const [GDPRApproved, setGDPRApproved] = useState(true);
  let { documents, setDocuments, setLoading, setIsAdmin } = useContext(DocumentsContext);
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const { accounts, instance } = useMsal();
  const content = useRef(null)

  if (theme !== undefined) {
    switchTheme(theme);
  } else if (localStorage.getItem("theme") != null) {
    setTheme(localStorage.getItem("theme"));
  } else {
    setTheme("Dark");
  }

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      let docs = [];
      let isAdmin = false;

      try {
        let gdpr = await getGDPR(accounts[0], instance);
        if (gdpr !== "True") setGDPRApproved(false);
        docs = await fetchDocs(accounts[0], instance);
        isAdmin = await fetchIsAdmin(accounts[0], instance);
      }
      catch (error) {
        if(error instanceof UnauthorisedError) {
          if(!location.pathname.includes("/401")) {
            history.push("/401")
          }
        }
      }

      if (JSON.parse(JSON.stringify(docs)) !== JSON.parse(JSON.stringify(documents))) setDocuments(docs);
      console.log("Version 1.1.4");
      setLoading(false)
      setIsAdmin(isAdmin);
    };

    function fetchData() {
      if (isAuthenticated) {
        setTimeout(() => fetch(), 500);
      }
    }
    fetchData();
    if(content.current) content.current.scroll(0, 0)
    window.scroll(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const leaveAlert = (logout) => {
    let locationPathList = location.pathname.split("/");
    let documentID = locationPathList.find((x) => x.includes("DOC"));
    let editing = locationPathList.find(x => x === "edit");
    if (documentID && editing) {
      let document = documents.find((x) => x.id === documentID);
      if 
      (
        window.confirm
        (
          "Your progress will be saved, changes will be visible to everyone with access to this document. Are you sure you want to " +
            (logout ? "log out" : "go home") +
            "? "
        )
      ) 
      {
        if (document) saveDocs(accounts[0], instance, document);
        if (logout) {
          localStorage.removeItem('userId');
          authProvider.logout();
        } else {
          history.push("/");
        }
      }
    } else {
      if (logout) {
        localStorage.removeItem('userId');
        authProvider.logout();
      } else {
        history.push("/");
      }
    }
  };

  return (
    <section className="main-section">
      <nav>
        {location.pathname !== "/" ? (
          <button className="go-back desktop" onClick={() => leaveAlert()}>
            <i className="fas fa-home"></i>Home
          </button>
        ) : theme === "Dark" ? (
          <div className="logo-container">
            <img
              className="logos"
              alt="Tharsus group logo"
              src="/Tharsus_Logo_WO.png"
            />
            <img
              className="logos"
              alt="Tharsus logo"
              src="/tharsus-white.png"
            />
            <img className="logos" alt="UW logo" src="/UW_Logo_White.svg" />
          </div>
        ) : (
          <div className="logo-container">
            <img
              className="logos"
              alt="Tharsus group logo"
              src="/Tharsus_Logo_Black.png"
            />
            <img
              className="logos"
              alt="Tharsus logo"
              src="tharsus-group-squarelogo-1572345906812.png"
            />
            <img className="logos" alt="UW logo" src="/UW_Logo_Black.svg" />
          </div>
        )}
        <div className="nav-right">
          <button onClick={() => leaveAlert(true)}>Logout</button>
          <label
            className="switch"
            onChange={(e) =>
              setTheme(e.target.checked === true ? "Dark" : "Light")
            }
          >
            <input
              type="checkbox"
              defaultChecked={theme === "Dark" ? true : false}
            />
            <span className="slider round">
              <div className="circle">
                <i
                  className={theme === "Dark" ? "fas fa-moon" : "fas fa-sun"}
                ></i>
              </div>
            </span>
          </label>
        </div>
      </nav>
      { GDPRApproved ?
        <section ref={content} className="content">{children}</section>
      :
        <div className="gdpr-popup">
          <p>To use this application, you must agree with our group Privacy Policy (Please ask HR for access if you can't access it already). If you agree, click below to continue. If not, please leave and speak to your manager.</p>
          <button onClick={() => { setGDPR(accounts[0], instance); setGDPRApproved(true) }}>Agree</button>
        </div>
      }
      {location.pathname !== "/" && (
        <button className="go-back mobile" onClick={() => leaveAlert()}>
          <i className="fas fa-home"></i>Home
        </button>
      )}
    </section>
  );
};

export default MainPageWrapper;
