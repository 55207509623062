import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import requestDivisionEmail from "../api/adminRequestEmail";
import fetchUsers from "../api/getUsers";
import Avatar from "../components/avatar";
import LoadingSpinner from "../components/loading-spinner";
import "../css/users.css";

const TeamData = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [allusers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDivision, setSelectedDivision] = useState("All");

  const { accounts, instance } = useMsal();
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      setUsers(await fetchUsers(accounts[0], instance, false, true));
      setAllUsers(await fetchUsers(accounts[0], instance, true, true));
      setLoading(false);
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let allUsersFiltered = allusers.filter(
    (x) =>
      x.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !users.includes(x)
  );
  let myTeamFiltered = users.filter((x) =>
    x.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const location = useLocation();

  let onAdminTools = location.pathname.includes("/admin");

  return (
    <>
      {onAdminTools && (
        <div className="admin-buttons">
          <h1>Admin Tools</h1>
          <NavLink className="button-style" to="/admin/leavers-archive">
            Edit leavers archive
          </NavLink>
          <NavLink className="button-style" to="/admin/secondment">
            Graduate secondments
          </NavLink>
          <NavLink className="button-style" to="/admin/test-documents">
            Create and delete test documents
          </NavLink>
          <div className="button-and-dropdown">
            <button
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to send an email to " +
                      accounts[0].username +
                      " summarising the current documents " +
                      (selectedDivision === "All"
                        ? "across Tharsus Group?"
                        : "for " + selectedDivision + "?")
                  )
                ) {
                  requestDivisionEmail(accounts[0], instance, selectedDivision);
                }
              }}
            >
              Generate summary email for{" "}
              {selectedDivision === "All"
                ? "all of Tharsus Group"
                : selectedDivision}
            </button>
            <select onChange={(e) => setSelectedDivision(e.target.value)}>
              <option value="All">All</option>
              <option value="Tharsus">Tharsus</option>
              <option value="Universal Wolf">Universal Wolf</option>
            </select>
          </div>
        </div>
      )}
      <input
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        placeholder="Search for a person"
      ></input>
      <h4 className="left-align-text">My Team</h4>
      <div className="people">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {myTeamFiltered.length > 0 ? (
              myTeamFiltered
                .sort((x, y) =>
                  x.name.toLowerCase() < y.name.toLowerCase() ? -1 : 1
                )
                .map((person) => {
                  return (
                    <NavLink
                      key={person.id}
                      className="person-card"
                      to={
                        (onAdminTools ? "/admin/" : "/user-data/") +
                        person.id +
                        "/" +
                        person.name
                      }
                    >
                      <Avatar url={person.imgUrl} email={person.emailAddress} />
                      <p>{person.name}</p>
                    </NavLink>
                  );
                })
            ) : (
              <p>There are no team members to display.</p>
            )}
          </>
        )}
      </div>
      {allUsersFiltered.length > 0 && (
        <>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <h4 className="left-align-text">Everyone else</h4>
              <div className="people">
                {allUsersFiltered
                  .sort((x, y) =>
                    x.name.toLowerCase() < y.name.toLowerCase() ? -1 : 1
                  )
                  .map((person) => {
                    return (
                      <NavLink
                        className="person-card"
                        to={
                          (onAdminTools ? "/admin/" : "/user-data/") +
                          person.id +
                          "/" +
                          person.name
                        }
                      >
                        <Avatar
                          url={person.imgUrl}
                          email={person.emailAddress}
                        />
                        <p>{person.name}</p>
                      </NavLink>
                    );
                  })}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default TeamData;
