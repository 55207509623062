import { useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import { createTestDocument, deleteTestDocuments } from "../api/testDocuments";
import "../css/dashboard.css";

const TestDocuments = () => {
  const { accounts, instance } = useMsal();

  const [type, setType] = useState("HR1000");

  const [colleagueEmail, setColleagueEmail] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [grandmanagerEmail, setGrandmanagerEmail] = useState("");

  const deleteDocuments = async () => {
    if (window.confirm("Are you sure you want to delete all test documents? This cannot be undone!")) {
      let success = await deleteTestDocuments(accounts[0], instance);

      if (success) {
        alert("Successfully deleted all test documents!")
      } else {
        alert("Failed to delete all documents.");
      }
    }
  };

  const createDocument = async () => {
    let success = await createTestDocument(accounts[0], instance, type, colleagueEmail, managerEmail, grandmanagerEmail);
    if (success) {
      alert("Successfully created document!")
      setColleagueEmail("");
      setManagerEmail("");
      setGrandmanagerEmail("");
      setType("HR1000")
    }
  };

  const inputValid = () => {
    return type !== "" && colleagueEmail.includes("@") && managerEmail.includes("@") && grandmanagerEmail.includes("@")
  }

  return (
    <>
      <h2>Test Documents</h2>
      <h3>
        Fill in and submit the form below to create a test document.
      </h3>
      <div className="vertical-form">
        <div className="group">
          <label>Document Type</label>
          <select
            onChange={(e) => setType(e.target.value)}
            value={type}
          >
            <option value="HR1000">2 month probationary review</option>
            <option value="HR1000A">4 month probationary review</option>
            <option value="HR1000B">6 month probationary review</option>
            <option value="HR1001">Objective setting</option>
            <option value="HR1001A">Objective mid-year review</option>
            <option value="HR1001B">Objective end-of-year review</option>
            <option value="HR1002">Year end review</option>
          </select>
        </div>
        <div className="group">
          <label>Colleague Email</label>
          <input
            onChange={(e) => setColleagueEmail(e.target.value)}
            value={colleagueEmail}
          ></input>
        </div>
        <div className="group">
          <label>Manager Email</label>
          <input
            onChange={(e) => setManagerEmail(e.target.value)}
            value={managerEmail}
          ></input>
        </div>
        <div className="group">
          <label>Grandmanager Email</label>
          <input
            onChange={(e) => setGrandmanagerEmail(e.target.value)}
            value={grandmanagerEmail}
          ></input>
        </div>
          <button onClick={() => inputValid() && createDocument()} style={inputValid() ? {backgroundColor: 'var(--primary-button-color)'} : {backgroundColor: 'var(--disabled-button-color)', cursor: "default"}}>Submit</button>
        <h3>
          Click the button below to delete all test documents.
        </h3>
        <button onClick={() => deleteDocuments()}>Delete all test documents</button>
      </div>
    </>
  )};

export default TestDocuments;
