import { useMsal } from '@azure/msal-react';
import React from 'react';
import { ifUserIs } from '../utils/getState';

const ViewerTable = ({document}) => {
    const { accounts } = useMsal();

    // Clone the document body
    let categories = [...document.body];

    if(ifUserIs(accounts[0].username, document.colleague)) {
        // Hide the third party feedback category unless there is data in it
        let thirdPartyCategory = categories.find(category => category.categoryName === "Third Party Feedback");
        if(thirdPartyCategory && thirdPartyCategory.managerComments === "") {
            let thirdPartyIndex = categories.findIndex(category => category.categoryName === "Third Party Feedback");
            if(thirdPartyIndex !== -1) {
                categories.splice(thirdPartyIndex, 1);
            } 
        }
    }

    return (
        <table>
            <h1 className="tr-title">{document.colleague.name}'s {document.documentType === "HR1000" ? "probationary" : "year-end"} review</h1>
            <tr>
                <th>Criteria</th>
                <th>Guidelines</th>
                {ifUserIs(accounts[0].username, document.colleague) ? <th>My Score</th> : <th>Colleague Score</th>}
                {ifUserIs(accounts[0].username, document.colleague) ? <th>My Comments</th> : <th>Colleague Comments</th>}
                <th>Agreed Score</th>
                <th>Manager Comments</th>
            </tr>
            {categories.map(category => {
                return <tr>
                    <td><h4 className="td-title">{category.categoryName}</h4></td>
                    <td>
                        <h4 className="print-heading">Guidelines</h4>
                        <p className="guidelines">{category.categoryDescription}</p>
                    </td>
                    <td>
                        {ifUserIs(accounts[0].username, document.colleague) ? <h4 className="print-heading">My Score</h4> : <h4 className="print-heading">Colleague Score</h4>}
                        <p className="print-score">{category.colleagueScore}</p>
                    </td>
                    <td>
                        {ifUserIs(accounts[0].username, document.colleague) ? <h4 className="print-heading">My Comments</h4> : <h4 className="print-heading">Colleague Comments</h4>}
                        <p>{category.colleagueComments}</p>
                    </td>
                    <td>
                        <h4 className="print-heading">Manager Score</h4>
                        <p className="print-score">{category.managerScore}</p>
                    </td>
                    <td>
                        <h4 className="print-heading">Manager Comments</h4>
                        <p>{category.managerComments}</p>
                    </td>
                </tr>
            })}
        </table> 
    )
}

export default ViewerTable;