import { useMsal } from "@azure/msal-react";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import saveDocs from "../../api/saveDocuments";
import submitDocs from "../../api/submitDocuments";
import EditObjective from "../../components/edit-objective";
import Objective from "../../components/objective";
import ObjectiveCard from "../../components/objective-card";
import DocumentsContext from "../../globalContext/documentsContext";
import { getState, ifUserIs } from "../../utils/getState";
import History from "../../utils/history";
import TaskWrapper from "./task-wrapper";
const ObjReviewEditor = () => {
  const { documentID } = useParams();

  // Get all documents
  const { documents, setDocuments } = useContext(DocumentsContext);

  // Get the specific document to display
  const [document, setDocument] = useState(
    documents.find((x) => x.id === documentID)
  );

  const [openedObjective, setOpenedObjective] = useState(null);
  const [reviewing, setReviewing] = useState(false);

  const { accounts, instance } = useMsal();
  let loggedInUsersEmail = accounts[0].username;

  let objectiveList;
  let color;

  let objectiveGuidance = "";

  if (document.documentType === "HR1001") {
    objectiveGuidance = `Please set ${document.colleague.name}’s objectives for this year, these should be cascaded from the Department objectives / Vision Mission Roadmap (VMR).`;
  } else {
    objectiveGuidance = `${
      document.colleague.name
    } has submitted their comments${
      document.documentType === "HR1001B" ? " and scores" : ""
    }, you should now arrange a one-to-one with them to discuss their progress. Once the one-to-one has taken place, please submit your comments${
      document.documentType === "HR1001B" ? " and agreed scores" : ""
    } below.`;
  }

  if (document) {
    objectiveList = document?.body;
    color = getState(document, loggedInUsersEmail).color;
  }

  const updateObjectives = (objective, toDelete) => {
    let newObjectiveList = objectiveList;
    let objectiveIndex = newObjectiveList.indexOf(openedObjective);
    if (objectiveIndex !== -1) {
      if (toDelete) {
        newObjectiveList.splice(objectiveIndex, 1);
      } else {
        // Updating an existing objective
        newObjectiveList[objectiveIndex] = objective;
      }
    } else {
      // Add new objective
      newObjectiveList.push(objective);
    }

    let newDocument = document;
    newDocument.body = newObjectiveList;
    setDocument(newDocument);

    let newDocuments = JSON.parse(JSON.stringify(documents));
    newDocuments[
      newDocuments.findIndex((x) => x.id === documentID)
    ] = newDocument;
    setDocuments(newDocuments);
  };

  const objectiveStatus = (objective) => {
    if (
      !document.documentType.includes("A") &&
      !document.documentType.includes("B")
    ) {
      // We don't care about comment status for the objectives setting stage
      return undefined;
    } else if (ifUserIs(accounts[0].username, document.colleague)) {
      // The colleague is logged in, so they should leave colleague comments
      if (objective.colleagueComments === "") {
        // Colleague has not commented
        return "needs-comment";
      } else {
        return "commented";
      }
    } else if (ifUserIs(accounts[0].username, document.manager)) {
      // The manager is logged in, so they should leave manager comments
      if (objective.colleagueComments === "") {
        return undefined;
      } else if (objective.managerComments === "") {
        return "needs-comment";
      } else {
        return "commented";
      }
    }
  };

  const canSubmit = () => {
    // Can the objectives be submitted?
    // If manager, all must have a manager comment
    // If colleague, all must have a colleague comment

    if (objectiveList.length < 1) return false;

    if(document.documentType === "HR1001")
    {
      // Check that the quota for performance and development objectives has been met
      let numPerfObjectives = 0;
      let numDevObjectives = 0;

      for (let objective of objectiveList) {
        if (objective.objectiveType === "Performance Objective") numPerfObjectives++;
        else if (objective.objectiveType === "Development Objective") numDevObjectives++;
      }

      // Prevent continuation if they don't meet the quota
      if (numPerfObjectives < 2) return false;
      if (numDevObjectives < 1) return false;
    }

    let allManagerCommented = true;
    let allColleagueCommented = true;

    let userIsManager = ifUserIs(accounts[0].username, document.manager);
    let userIsColleague = ifUserIs(accounts[0].username, document.colleague);

    for (let i = 0; i < objectiveList.length; i++) {
      if (!objectiveList[i].colleagueComments) allColleagueCommented = false;

      // Manager only needs to comment if the colleague has commented
      if (
        objectiveList[i].colleagueComments !== "" &&
        !objectiveList[i].managerComments
      )
        allManagerCommented = false;
    }

    // Manager and state 0
    if (
      (userIsManager && allManagerCommented) ||
      (userIsColleague && allColleagueCommented)
    ) {
      return true;
    } else if (userIsManager) {
      if (document.documentState === 0) {
        // Initial objectives creation, manager comments not required yet
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const submitObjectives = () => {
    let message = "";
    if (ifUserIs(accounts[0].username, document.manager))
      message = `Please make sure you have held a one-to-one meeting with ${document.colleague.name} before submitting these objectives. You will not be able to change them later.`;
    else
      message = `Are you sure you want to submit your comments? You will not be able to change these once submitted.`;
    if (canSubmit() && window.confirm(message)) {
      submitDocs(accounts[0], instance, false, document);
      History.goBack();
    }
  };

  return (
    <>
      {!reviewing ? (
        <TaskWrapper document={document}>
          {openedObjective === null ? (
            <div className="objective-list">
              {ifUserIs(accounts[0].username, document.manager) && (
                <article className="objective-text">
                  <p>{objectiveGuidance}</p>
                  {document.documentType === "HR1001" && (
                    <p>
                      You should set a minimum of 2 Performance Objectives and 1
                      Development Objective.
                    </p>
                  )}
                  {document.documentType === "HR1001A" && (
                    <p>
                      At this point you have the ability to set new objectives
                      if required.
                    </p>
                  )}
                </article>
              )}
              {document.body.map((objective) => {
                return (
                  <Objective
                    openEditor={() => setOpenedObjective(objective)}
                    objective={objective}
                    status={objectiveStatus(objective)}
                  />
                );
              })}
              {!document.documentType.includes("B") &&
                ifUserIs(accounts[0].username, document.manager) && (
                  <button onClick={() => setOpenedObjective(-1)}>
                    Add an objective
                  </button>
                )}
              <button
                style={
                  canSubmit()
                    ? { backgroundColor: "var(" + color + ")" }
                    : {
                        backgroundColor: "var(--disabled-button-color)",
                        cursor: "default",
                      }
                }
                onClick={() => canSubmit() && setReviewing(true)}
              >
                Review Objectives
              </button>
            </div>
          ) : (
            <>
              {ifUserIs(accounts[0].username, document.manager) && (
                <>
                  {document.documentType !== "HR1001B" && (
                    <article className="objective-text">
                      <p>Objectives should be:</p>
                      <p>
                        Specific – State what the objective is and what they
                        will do
                      </p>
                      <p>
                        Measurable – Provide a metric on how they will know it
                        is achieved
                      </p>
                      <p>
                        Agreed – Objective is agreed upon by both colleague and
                        manager as being attainable.
                      </p>
                      <p>
                        Realistic - Makes sense within your job and function,
                        improves the business in some way
                      </p>
                      <p>
                        Timebound – State when it needs to be done by, be
                        specific on date or timeframe
                      </p>
                    </article>
                  )}
                </>
              )}
              <EditObjective
                isColleague={ifUserIs(accounts[0].username, document.colleague)}
                isManager={ifUserIs(accounts[0].username, document.manager)}
                objective={openedObjective}
                type={document.documentType}
                saveObjective={(objective) => {
                  updateObjectives(objective);
                  saveDocs(accounts[0], instance, document);
                  setOpenedObjective(null);
                }}
                deleteObjective={(objective) => {
                  updateObjectives(objective, true);
                  saveDocs(accounts[0], instance, document);
                  setOpenedObjective(null);
                }}
                closeEditor={() => setOpenedObjective(null)}
              ></EditObjective>
            </>
          )}
        </TaskWrapper>
      ) : (
        <div className="obj-doc">
          {ifUserIs(accounts[0].username, document.manager) &&
            document.documentType === "HR1001" && (
              <p className="objective-text">
                Now you have prepared {document.colleague.name}’s objectives,
                please arrange a one-to-one with them to discuss their
                objectives for this year. Once this conversation has taken place
                and objectives are agreed, you should submit their objectives
                below.
              </p>
            )}
          {ifUserIs(accounts[0].username, document.manager) &&
            document.documentType === "HR1001A" && (
              <p className="objective-text">
                Please review your comments before submitting to{" "}
                {document.colleague.name}
              </p>
            )}
          {ifUserIs(accounts[0].username, document.manager) &&
            document.documentType === "HR1001B" && (
              <p className="objective-text">
                Please review your comments and agreed scores before submitting
                to {document.colleague.name}
              </p>
            )}
          {ifUserIs(accounts[0].username, document.colleague) &&
            document.documentType === "HR1001A" &&
            document.documentState === 0 && (
              <p className="objective-text">
                Please review your comments below, once submitted your manager
                will arrange a mid year review discussion with you.
              </p>
            )}
          {ifUserIs(accounts[0].username, document.colleague) &&
            document.documentType === "HR1001B" &&
            document.documentState === 0 && (
              <p className="objective-text">
                Please review your comments below, once submitted your manager
                will arrange an end of year review discussion with you.
              </p>
            )}
          {document.body.map((objective) => {
            return <ObjectiveCard objective={objective} reviewing={true} />;
          })}
          <div className="slide-buttons">
            <button onClick={() => setReviewing(false)}>Edit Objectives</button>
            <button
              style={{ backgroundColor: "var(" + color + ")", color: "white" }}
              onClick={() => submitObjectives()}
            >
              {ifUserIs(accounts[0].username, document.manager)
                ? "Submit Objectives to " +
                  document.colleague.name.split(" ")[0]
                : "Submit Comments"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ObjReviewEditor;
