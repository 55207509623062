import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { DateTime } from 'luxon'
import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Filters from '../components/filters'
import Tasks from '../components/tasks'
import '../css/dashboard.css'
import DocumentsContext from '../globalContext/documentsContext'

const Dashboard = () => {
    let today = new Date().toISOString();
    let sixMonthsAgo = new Date(DateTime.fromISO(today).minus({months: 6}).toISO());
    let sixMonthsFromNow = new Date(DateTime.fromISO(today).plus({months: 6}).toISO());

    const { documents, isAdmin } = useContext(DocumentsContext);
    const [filterString, setFilter] = useState("");
    const [startDate, setStartDate] = useState(sixMonthsAgo);
    const [endDate, setEndDate] = useState(sixMonthsFromNow);
    const [allowedAppraisalTypes, setAllowedAppraisalTypes] = useState(["HR1000", "HR1001", "HR1002"])

    const isAuthenticated = useIsAuthenticated();
    
    const { accounts } = useMsal();

    return (
        <>
            {isAuthenticated && 
                <h1 className="heading">Hi {accounts[0].name.split(" ")[0]}, what would you like to do?</h1>
            }
            
            <main className="dashboard">
                <aside className="left">
                    <div className="data-buttons">
                        <h4 className="left-align-text">Completed documents</h4>
                        <NavLink className="button-style button-margin-bottom" to="my-data">{accounts[0].name.split(" ")[0]}'s Documents</NavLink>
                        <NavLink className="button-style button-margin-bottom" to="team-data">Your Team's Documents</NavLink>
                        {isAdmin && <NavLink className="button-style button-margin-bottom" to="admin">Admin Tools</NavLink>}
                    </div>
                    <Filters setFilter={setFilter} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} allowedAppraisalTypes={allowedAppraisalTypes} setAllowedAppraisalTypes={setAllowedAppraisalTypes}/>
                </aside>
                <Tasks documents={documents} filterString={filterString} startDate={startDate} endDate={endDate} allowedAppraisalTypes={allowedAppraisalTypes}/>
            </main>
        </>
    )
}

export default Dashboard;