import { PublicClientApplication } from '@azure/msal-browser';
 
// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/fdac2f90-6724-4400-88ff-e74d7e79eb2e',
    clientId: 'dc8336ba-6686-4faa-9c56-24c4603002ea',
    protocolMode: 'AAD'
  }
};
 
export const authProvider = new PublicClientApplication(config)