import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { useState } from "react";
import { Route, Router, Switch } from "react-router-dom";
import LoadingSpinner from "./components/loading-spinner";
import "./css/App.css";
import "./css/buttons.css";
import "./css/colorPalette.css";
import "./css/form.css";
import "./css/layouts.css";
import "./css/responsive.css";
import "./css/table.css";
import { DocumentsProvider } from "./globalContext/documentsContext";
import MainPageWrapper from "./layouts/main-page-wrapper";
import AcceptReject from "./pages/accept-reject";
import AdminEditUser from "./pages/admin-edit-user";
import Blacklist from "./pages/blacklist";
import Dashboard from "./pages/dashboard";
import Unauthorised from "./pages/errors/401";
import PageNotFound from "./pages/errors/404";
import MyData from "./pages/my-data";
import Secondment from "./pages/secondment";
import ObjReviewEditor from "./pages/tasks/objectives-review-editor";
import ObjReviewViewer from "./pages/tasks/objectives-review-viewer";
import ProReviewEditor from "./pages/tasks/probationary-review-editor";
import ProReviewViewer from "./pages/tasks/probationary-review-viewer";
import TeamData from "./pages/team-data";
import TestDocuments from "./pages/test-documents";
import history from "./utils/history";

function App() {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const value = {
    documents,
    setDocuments,
    loading,
    setLoading,
    isAdmin,
    setIsAdmin,
  };
  const { accounts } = useMsal();

  useMsalAuthentication("redirect");
  return (
    <div className="App">
      {accounts.length > 0 && (
        <DocumentsProvider value={value}>
          <Router history={history}>
            <MainPageWrapper>
              <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route
                  exact
                  path="/accept/:documentID"
                  component={AcceptReject}
                />
                <Route exact path="/my-data" component={MyData} />
                <Route exact path="/user-data/:id/:name" component={MyData} />
                <Route exact path="/team-data" component={TeamData} />
                <Route exact path="/admin" component={TeamData} />
                <Route
                  exact
                  path="/admin/leavers-archive"
                  component={Blacklist}
                />
                <Route
                  exact
                  path="/admin/test-documents"
                  component={TestDocuments}
                />
                <Route exact path="/admin/secondment" component={Secondment} />
                <Route
                  exact
                  path="/admin/:id/:name"
                  component={AdminEditUser}
                />
                <Route
                  exact
                  path="/admin/:id/:name/:documentID"
                  component={AdminEditUser}
                />
                <Route exact path="/401" component={Unauthorised} />

                {/* Appraisal pages */}
                <Route
                  exact
                  path="/probationary-review/edit/:documentID"
                  component={
                    documents.length > 0 ? ProReviewEditor : LoadingSpinner
                  }
                />
                <Route
                  exact
                  path="/probationary-review/view/:documentID/:readOnly/:completed"
                  component={ProReviewViewer}
                />

                <Route
                  exact
                  path="/year-end-review/edit/:documentID"
                  component={
                    documents.length > 0 ? ProReviewEditor : LoadingSpinner
                  }
                />
                <Route
                  exact
                  path="/year-end-review/view/:documentID/:readOnly/:completed"
                  component={ProReviewViewer}
                />

                <Route
                  exact
                  path="/objectives-review/edit/:documentID"
                  component={
                    documents.length > 0 ? ObjReviewEditor : LoadingSpinner
                  }
                />
                <Route
                  exact
                  path="/objectives-review/view/:documentID/:readOnly/:completed"
                  component={ObjReviewViewer}
                />

                <Route path="*" component={PageNotFound} />
              </Switch>
            </MainPageWrapper>
          </Router>
        </DocumentsProvider>
      )}
    </div>
  );
}

export default App;
