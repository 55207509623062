import makeApiRequest from './makeApiRequest';

export default async function fetchBlacklist(account, instance) {
    try {
        return await makeApiRequest(account, instance, 'get', 'BlacklistGet');
    } catch (error) {
        alert("You do not have permission to edit the blacklist.");
    }
}

export async function blacklistRemove(account, instance, email) {
    try {
        return await makeApiRequest(account, instance, 'put', 'BlacklistRemove', {userEmail: email});
    }
    catch (error) {
        return false;
    }
}

export async function blacklistAdd(account, instance, email) {
    try {
        return await makeApiRequest(account, instance, 'put', 'BlacklistAdd', {userEmail: email});
    }
    catch (error) {
        return false;
    }
}