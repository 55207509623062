import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


const Filters = ({setFilter, startDate, setStartDate, endDate, setEndDate, allowedAppraisalTypes, setAllowedAppraisalTypes}) => {
    const handleCheckbox = (e, appraisalType) => {
        if(e.target.checked) {
            setAllowedAppraisalTypes([...allowedAppraisalTypes, appraisalType])
        }
        else {
            setAllowedAppraisalTypes(allowedAppraisalTypes.filter(t => t !== appraisalType))
        }
    }
    return (
        <aside className="filters">
            <h4 className="left-align-text">Search upcoming tasks</h4>
            <div className="advanced-search">
                <input onChange={(e) => setFilter(e.target.value)} placeholder="Type a name, email or task..."/>
            </div>
            <div className="date-buttons">
                <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd/MM/yyyy"
                    strictParsing
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <div className="spacer"></div>
                <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd/MM/yyyy"
                    strictParsing
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
            </div>
            <h4 className="left-align-text">Filter by Appraisal Type</h4>
            <div className="apprasial-type-filters">
                <label style={{borderColor: "var(--probationary-color)"}}>
                    Probationary Review
                    <input onChange={(e) => handleCheckbox(e, "HR1000")} type="checkbox" defaultChecked={allowedAppraisalTypes.includes("HR1000") ? true : false} id="probCheck" name="probCheck"/>
                </label>
                <label style={{borderColor: "var(--objectives-color)"}}>
                    Objective Setting
                    <input onChange={(e) => handleCheckbox(e, "HR1001")} type="checkbox" defaultChecked={allowedAppraisalTypes.includes("HR1001") ? true : false} id="objCheck" name="objCheck"/>
                </label>
                <label style={{borderColor: "var(--year-end-color)"}}>
                    Year-end Review
                    <input onChange={(e) => handleCheckbox(e, "HR1002")} type="checkbox" defaultChecked={allowedAppraisalTypes.includes("HR1002") ? true : false} id="behaveCheck" name="behaveCheck"/>
                </label>
            </div>
        </aside>
    )
}

export default Filters;